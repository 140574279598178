import { useMediaQuery } from "react-responsive";
import { CommonServices } from "../../utils/commonServices";
import { ordersHistoryList } from "../../config/constants";

const OrderNotifications = (props) => {
    const { notification } = props
    const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
    const commonServices = new CommonServices();

    return (
        <>
            <div class="each-notification row m-0" onClick={() => { if (isMobile) { props.displayOrderDetails(notification.extraInfo) } }}>
                <div className="col-lg-2 col-md-2 col-sm-2 d-flex">
                    <img
                        // src="/assets/icons/notification-icons/ready-for-delivery.png"
                        src={ordersHistoryList[(notification.extraInfo.order_status || 0).toString()]}
                        className="w-100 m-auto"
                        alt="no-img"
                    />
                </div>
                <div className="col-lg-10 col-md-10 col-sm-10 ps-0">
                    <div className="row m-0">
                        <div className="col-lg-8 col-md-8 col-sm-12">
                            <p class="title m-0">{notification.title}</p>
                            <p class="short-desc m-0">{notification.short_discription}</p>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            {!isMobile ? (
                                <>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-8 ">
                                            <p class="date m-0 text-end">
                                                {
                                                    commonServices
                                                        .formateDate(notification.created_at)
                                                        .split(", ")[0]
                                                }
                                            </p>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-4 ">
                                            <p class="date m-0 text-end">
                                                {
                                                    commonServices
                                                        .formateDate(notification.created_at)
                                                        .split(", ")[1]
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <p class="date my-1 text-start">
                                        {commonServices.formateDate(notification.created_at)}
                                    </p>
                                </>
                            )}

                            {!isMobile && (
                                <button class="track" onClick={() => props.displayOrderDetails(notification.extraInfo)}>
                                    <i class="fa-solid fa-arrow-right"></i>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrderNotifications;
