import React, { useEffect, useState } from 'react';
import { authSelector } from '../../features/auth/authSlice';
import './Home.css';
import { useDispatch, useSelector } from 'react-redux';
import Banner from '../../components/Banner';
import Benefits from '../../components/Benefits';
import CarServices from '../../components/CarServices';
import CarSpa from '../../components/CarSpa';
import Download from '../../components/Downloadapp';
import Garages from '../../components/Garages';
import MetamechxWorks from '../../components/HowMetamechxWorks';
import Refer from '../../components/ReferandEarn';
import SearchServices from '../../components/SearchServices/SearchServices';
import Testimonials from '../../components/Testimonials';
import { addressSelector } from "../../features/account/addressSlice";
import { getBanners } from '../../features/banner/bannerSlice';
import { emergencyserviceselector, getServiceCategories } from '../../features/emergency/emergencySlice';
import { getgarages } from '../../features/garage/garageSlice';
import { getbrands, getVehicleType, homeselector } from '../../features/home/homeSlice';
import { carspaserviceselector, getSpaGarageWorkTypes, getspaservices } from '../../features/spa/spaserviceSlice';
import { CommonServices } from '../../utils/commonServices';
import { NODE_ENV } from '../../config'
import Notifications from '../../components/Notifications/Notifications';
import { getUnreadCount, notificationsSelector, reset } from '../../features/notifications/notificationsSlice';
import { STATUS_CODES } from '../../config/constants';
import Loader from "../../common/Loader/Loader";

const Home = () => {
  const { isAuthenticated } = useSelector(authSelector);
  const { vehicleType } = useSelector(homeselector);
  const { categories } = useSelector(emergencyserviceselector);
  const { carSpaWorkTypes } = useSelector(carspaserviceselector);
  const { address } = useSelector(addressSelector)
  const { isLoading, unReadCount ,markAsReadAllResp} = useSelector(notificationsSelector)
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false)
  const commonServices = new CommonServices();

  useEffect(() => {
    if (vehicleType == null) {
      dispatch(getVehicleType());
    }
    else {
      const data = { vehicleType: commonServices.getLocalStrg("VEHICLE_TYPE") };
      dispatch(getbrands(data));
      // dispatch(getServiceCategories(data));
      if (categories == null) {
        dispatch(getServiceCategories(data));
      }
      else {
        setLoading(false);
      }
    }
  }, [vehicleType, categories]);


  useEffect(() => {
    setLoading(false);
    const bannerData = {}
    dispatch(getBanners(bannerData));
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getUnreadCount())
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (carSpaWorkTypes === null) {
      dispatch(getSpaGarageWorkTypes())
    }
    else {
      let carSpaData = {};
      let garageData = {};
      let userLocation;
      let pincode;

      if (NODE_ENV === "development") {
        userLocation = commonServices.getLocalStrgJSON("MMX_USER_LATLON") || { "lat": 12.9399446, "lng": 77.7337516 };
        pincode = commonServices.getLocalStrg("MMX_USER_PINCODE") || "560056";
      } else {
        userLocation = commonServices.getLocalStrgJSON("MMX_USER_LATLON");
        pincode = commonServices.getLocalStrg("MMX_USER_PINCODE");
      }

      carSpaData = {
        serviceWorkTypeIds: carSpaWorkTypes.carSpaWorkTypes,
        userLocation,
        pincode
      }
      garageData = {
        serviceWorkTypeIds: carSpaWorkTypes.garageWorkTypes,
        userLocation,
        pincode
      }
      dispatch(getspaservices(carSpaData))
      dispatch(getgarages(garageData))

    }
  }, [carSpaWorkTypes, address])

  const closeModal = () => {
    dispatch(getUnreadCount());
    dispatch(reset())
    setShowNotificationModal(false)
  }

  useEffect(()=>{
    if(markAsReadAllResp && markAsReadAllResp.statuscode === STATUS_CODES[200]){
        dispatch(getUnreadCount());
        dispatch(reset());
    }
},[markAsReadAllResp])

  return (
    // <main>
    <>
      {/*===========================================banner start===========================================*/}
      <Banner />
      {/*===========================================banner end===========================================*/}

      {/*===========================================main theme start===========================================*/}

      <div className="container-fluid home-page" style={{ overflow: "clip" }}>

        <div className="row main_device d-flex justify-content-between">
          <div className="left col-lg-8 col-md-12 col-sm-12 order-lg-1 order-md-2 order-2 h-100 float-start main_device_left" >
            <div>{loading}</div>
            {!loading ? (
              <React.Fragment>
                {/* <EmegencyServices /> */}
                <CarServices />
              </React.Fragment>) : null
            }
            <CarSpa />
            <Garages />
            {/* <Giftshop /> */}
            <Testimonials />
            <MetamechxWorks />
            <Benefits />
            <Refer />
            {/* <Download /> */}
          </div>
          <div class="col-lg-4 col-sm-12 order-1 float-end main_device_right"
            style={{
              height: "fit-content",
              position: "sticky",
              top: "100px",
              zIndex: 3
            }}>
            <SearchServices />
          </div>
        </div>
        {!showNotificationModal && isAuthenticated && <div class={`notification-icon-background  ${unReadCount > 0 && "bell"}`} onClick={() => setShowNotificationModal(true)}>
          {unReadCount > 0 && <p class={`${unReadCount > 0 && "heartbeat"}`} ></p>}
          <img class={`${unReadCount > 0 && "bell-icon"}`} src="/assets/icons/notificationIcon.png" alt='no-img' width={40}></img>
          <p class="notification-count">{(unReadCount && unReadCount < 10) ? `0${unReadCount}` : unReadCount || 0}</p>
        </div>}

      </div>
      {showNotificationModal && <Notifications closeModal={() => closeModal()} />}
      {isLoading && <Loader />}
      {/*===========================================main theme end===========================================*/}
    </>
    // </main>
  )
};

export default Home;
