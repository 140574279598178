import { NOTIFICATION_LIST, NOTIFICATION_MARK_AS_READ_ALL, NOTIFICATION_UNREAD_COUNT, NOTIFICATION_UPDATE_AS_READ } from "../config/constants";
import { CommonServices } from "../utils/commonServices";
import { HttpClient } from "../utils/httpClient";

class Notifications {
    constructor() {
        this.httpClient = new HttpClient();
        this.commonServices = new CommonServices();
    }

    async getUnreadCount() {
        try {
            const response = await this.httpClient.get(NOTIFICATION_UNREAD_COUNT);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async getNotifications(data) {
        try {
            const response = await this.httpClient.get(NOTIFICATION_LIST + `?limit=${data.limit}&offset=${data.offSet}`);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async notificationsUpdateAsRead(notificationIDs) {
        try {
            const response = await this.httpClient.put(NOTIFICATION_UPDATE_AS_READ, { notificationIds: notificationIDs });
            return response;
        } catch (error) {
            throw error;
        }
    }

    async markAsReadAll() {
        try {
            const response = await this.httpClient.put(NOTIFICATION_MARK_AS_READ_ALL);
            return response;
        } catch (error) {
            throw error;
        }
    }
}

export { Notifications };
