import { Navigate, useRoutes } from 'react-router-dom';

// layouts
import OuterLayout from '../common/Layout/OuterLayout';
import About from '../components/About/About';
import Garages3 from '../components/Garages/Garages3';
// import Login from '../components/Login/Login';
import MMXWallet from '../components/MOPWallet/MOPWallet';
import MyAddresses from '../components/MyAddresses/MyAddresses';
import MyVehicles from '../components/MyVehicles/MyVehicles';
import Profile from '../components/ProfileDetails/Profile';
import Account from '../pages/Account/Account';
import Home from '../pages/Home/Home';
import NotFound from '../pages/page404';
import Services from '../pages/Services';
import RouteNavigator from './RouteNavigator';
import ProtectedRoute from '../router/ProtectedRoute';
import Checkout from '../components/Checkout/Checkout';
import MainCartPage from '../components/MainCartPage/MainCartPage';
import ReferandEarn from '../components/ReferandEarn';
import SelectionNav from "../components/SelectionNav";
import ServicePackages from "../components/ServicePackages";
import Orders from '../components/OrdersPage/Orders';
import OrderSuccessfull from '../pages/OrderSuccessful';
import Loader from '../common/Loader/Loader';
import ContactUs from '../components/ContactUs/ContactUs';
import Logout from '../components/Logout/Logout';
import Partner from '../components/Partner/Partner';
import TermsAndConditions from '../components/TermsAndConditions/TermsAndConditions';
import OrderDetailsModal from '../components/OrderDetails/OrderDetails';
import ValidateCheckoutRoute from './ValidateCheckoutRoute';
import PaymentPage from '../pages/PaymentPage';


export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <OuterLayout />,
      children: [
        {
          path: '/',
          element: <RouteNavigator />,
        },
        {
          path: 'home',
          element: (
            // <PublicRoute>
            <Home />
            // </PublicRoute>
          ),
        },
        {
          path: 'services',
          element: <Services />,
          children: [
            {
              path: '',
              element: (
                <>
                  <SelectionNav />
                  <ServicePackages />
                </>
              )
            },
            {
              path: 'service-centers',
              element: <Garages3 />
            },
            {
              path: 'checkout',
              element: <ValidateCheckoutRoute>
                <Checkout />
              </ValidateCheckoutRoute>
            },
            {
              path: 'mycart',
              element: <MainCartPage />
            }
          ]
        },
        {
          path: 'about',
          element: (
            // <PublicRoute>
            <About />
            // </PublicRoute>
          ),
        },
        {
          path: 'profile',
          element: <ProtectedRoute >
            <Account />
          </ProtectedRoute>,
          children: [
            {
              path: '',
              element: <Profile />
            },
            {
              path: 'wallet',
              element: <MMXWallet />
            },
            {
              path: 'myvehicles',
              element: <MyVehicles />
            },
            {
              path: 'myaddresses',
              element: <MyAddresses />
            },
            {
              path: 'order-history',
              element: <Orders />
            },
            {
              path: 'payment',
              // element: <PaymentOptions />
            },
            {
              path: 'refer&earn',
              element: <ReferandEarn />
            },
            {
              path: 'contactus',
              element: <ContactUs />
            },
            {
              path: 'logout',
              element: <Logout />
            },
          ]
        },
        {
          path: 'partners',
          element: (
            // <PublicRoute>
            <Partner />
            // </PublicRoute>
          ),
        },
        {
          path: 'order-details',
          element: <OrderDetailsModal />,
        },
        { path: 'order-successfull', element: <OrderSuccessfull /> },
        { path: 'terms&conditions', element: <TermsAndConditions /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
        { path: "loader", element: <Loader /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
    { path: '/py*', element: <PaymentPage /> }
  ]);
}