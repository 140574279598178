import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetails, orderSelector, reset } from "../../features/account/orderSlice";
import { CommonServices } from "../../utils/commonServices";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "react-responsive";
import OrderEstimation from "../OrderEstimation/OrderEstimation";
import { STATUS_CODES } from "../../config/constants";

const OrderDetails = (props) => {
  const commonServices = new CommonServices();
  const dispatch = useDispatch();
  const { handleClose, show } = props;
  const { orderDetailsResp, updateEstimationResp, selectedOrder } = useSelector(orderSelector);
  const [progressBarWidth, setProgressbarWidth] = useState("0%");
  const [bookingDate, setBookingDate] = useState(null);
  const [slotDate, setSlotDate] = useState(null)
  const [showExtraServicesList, setShowExtraServiceList] = useState(false)
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const [showOrderEstimantion, setShowOrderEstimation] = useState(false)

  useEffect(() => {
    if (orderDetailsResp) {
      if (orderDetailsResp.orderStatus.status === 1) {
        setProgressbarWidth("0%");
      } else if (
        orderDetailsResp.orderStatus.status === 2 ||
        orderDetailsResp.orderStatus.status === 3 ||
        orderDetailsResp.orderStatus.status === 4
      ) {
        setProgressbarWidth("33%");
      } else if (orderDetailsResp.orderStatus.status === 5) {
        setProgressbarWidth("66%");
      } else if (
        orderDetailsResp.orderStatus.status === 6 ||
        orderDetailsResp.orderStatus.status === 7
      ) {
        setProgressbarWidth("99%");
      }

      const formattedDate = commonServices.formateDate(
        orderDetailsResp.created_at
      );
      setBookingDate(formattedDate);

      const slotDate = commonServices.formateDate(
        orderDetailsResp.slot_date
      ).split(",")[0];
      setSlotDate(slotDate);
      if (isMobile && orderDetailsResp?.estimation_status === 1) {
        setShowOrderEstimation(true)
      }
    } else {
      if (selectedOrder) {

        dispatch(getOrderDetails(selectedOrder))
      }
    }
  }, [orderDetailsResp]);

  useEffect(() => {
    if (isMobile) {
      if (updateEstimationResp?.statuscode === STATUS_CODES[200]) {
        dispatch(getOrderDetails(orderDetailsResp?.orderId))
      }
    }
  }, [updateEstimationResp])


  return (
    <>
      <div class={`${isMobile && "mt-5 overflow-hidden"}`}>
        <div class="row">
          <div className="col-lg-11 col-md-11 col-sm-12 pb-3">
            <h5 class="text-center m-0">Order Details</h5>
          </div>
          {!isMobile && <div className="col-lg-1 col-md-1">
            <img
              class="pointer"
              src="../assets/icons/cross.png"
              style={{ width: "20px" }}
              onClick={() => {
                handleClose();
              }}
              alt="..."
            ></img>
          </div>}
          <hr class="m-0" />
        </div>
        <div class="order-details-modal">
          <div class={`${!isMobile && "order-details-body"}`}>
            <div class="row">
              <div className="col-lg-2 col-md-2 col-sm-2 mx-4 my-2 p-0">
                <img
                  src={orderDetailsResp?.service_center_profile_pic}
                  class="w-100 h-100"
                  alt=""
                />
              </div>
              <div className="col-lg-8 col-md-8 col-sm-6 align-items-center p-0 m-auto mx-0">
                <p class="m-0 service-center-name">
                  {orderDetailsResp ? (
                    orderDetailsResp.service_center_dispaly_name
                  ) : (
                    <Skeleton height={15} />
                  )}
                </p>
                <p class="m-0 service-center-address text-truncate">
                  {orderDetailsResp ? (
                    orderDetailsResp.service_center_full_address
                  ) : (
                    <Skeleton height={15} />
                  )}
                </p>
                {orderDetailsResp ? (
                  <p class="m-0 booking-details">Booked on {bookingDate}</p>
                ) : (
                  <Skeleton height={15} />
                )}
              </div>
              <hr class="m-0" />
            </div>
            <div class=" py-2">
              <div class="mx-3 mb-2 d-flex align-items-center justify-content-between">
                <p class="fw-600 m-0"> Service Charges</p>
                {orderDetailsResp ? (
                  <div class="d-flex justify-content-end  charges">
                    <p class="m-0">
                      {/* <i class="fa fa-inr" aria-hidden="true"></i> */}
                      {commonServices.numberFormat(orderDetailsResp?.total_service_cost_after_discount)}
                    </p>
                  </div>
                ) : (
                  <>
                    <Skeleton height={15} width={100} />
                  </>
                )}
              </div>
              {orderDetailsResp?.extraServiceList?.length > 0 && <div class="mx-3  mb-2 d-flex align-items-center justify-content-between">
                <p class="fw-600 m-0"> Added Extra Services
                  <span class="pointer" style={{ fontSize: "10.5px", color: "#379b63", marginLeft: "3px" }}
                    onClick={() => setShowExtraServiceList(!showExtraServicesList)}> View all
                    <i class={`fa-solid ${showExtraServicesList ? "fa-chevron-up" : "fa-chevron-down"}`}></i>
                  </span>
                </p>
                {orderDetailsResp ? (
                  <div class="d-flex justify-content-end charges">
                    <p class="m-0">
                      {/* <i class="fa fa-inr" aria-hidden="true"></i> */}
                      {commonServices.numberFormat(orderDetailsResp?.total_extra_service_cost)}

                    </p>
                  </div>
                ) : (
                  <>
                    <Skeleton height={15} width={100} />
                  </>
                )}
              </div>}
              <div class={`${showExtraServicesList ? "show-extra-services-list" : "hide-extra-services-list"}`}>
                {
                  orderDetailsResp?.extraServiceList?.map((item) => {
                    return (
                      <div class="mx-3  mb-2 d-flex align-items-center justify-content-between" style={{ color: "gray" }}>
                        <p class="my-0" style={{ fontSize: "12px", marginLeft: "30px" }}>
                          {item.jobcardServiceName} <span style={{ color: "#379B63" }}>{"(Qty:"}{item.quantity}{")"}</span>
                        </p>
                        <div class="d-flex justify-content-end  charges">
                          <p class="my-0" style={{ fontSize: "12px" }}>{commonServices.numberFormat(item.total_amount)}</p>
                        </div>
                      </div>
                    )
                  })
                }
              </div>

              {orderDetailsResp?.total_coupon_discount > 0 && <div class="mx-3  mb-2 d-flex align-items-center justify-content-between">
                <p class="fw-600 m-0"> Coupon Discount</p>
                {orderDetailsResp ? (
                  <div class="d-flex justify-content-end  charges">
                    <p class="m-0">
                      {/* <i class="fa fa-inr" aria-hidden="true"></i> */}
                      - {commonServices.numberFormat(orderDetailsResp?.total_coupon_discount)}

                    </p>
                  </div>
                ) : (
                  <>
                    <Skeleton height={15} width={100} />
                  </>
                )}
              </div>}
              {orderDetailsResp?.wallet_amount > 0 && <div class="mx-3  mb-2 d-flex align-items-center justify-content-between">
                <p class="fw-600 m-0"> Wallet Discount</p>
                {orderDetailsResp ? (
                  <div class="d-flex justify-content-end charges">
                    <p class="m-0">
                      {/* <i class="fa fa-inr" aria-hidden="true"></i> */}
                      - {commonServices.numberFormat(orderDetailsResp?.wallet_amount)}
                    </p>
                  </div>
                ) : (
                  <>
                    <Skeleton height={15} width={100} />
                  </>
                )}
              </div>}
            </div>
            <div class="row">
              <hr class="m-0" />
            </div>
            <div class=" py-2">
              <div class="mx-3 d-flex align-items-center justify-content-between">
                <h6 class="fw-600 m-0"> Service Total</h6>
                {orderDetailsResp ? (
                  <div class="d-flex justify-content-end w-15 charges">
                    <p class="m-0 fw-700">

                      {commonServices.numberFormat(orderDetailsResp?.total_cost_including_extra_service)}

                    </p>
                  </div>
                ) : (
                  <>
                    <Skeleton height={15} width={100} />
                  </>
                )}
              </div>
            </div>
            <div class="row">
              <hr class="m-0" />
            </div>
            <div class=" py-2">
              <div class="mx-3">
                <h5 class="fw-600" style={{ fontSize: "17px" }}> Order Details</h5>
                <div class="order-details row mb-2">
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <p class="m-0 details-headings">Slot Time & Date</p>
                    {orderDetailsResp ? (
                      <p class="m-0 fw-600">{slotDate} - {orderDetailsResp?.slotDetails?.from_time}</p>
                    ) : (
                      <Skeleton height={15} />
                    )}
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <p class="m-0 details-headings">Booking ID</p>
                    {orderDetailsResp ? (
                      <p class="m-0 fw-600">{orderDetailsResp?.orderNo}</p>
                    ) : (
                      <Skeleton height={15} />
                    )}
                  </div>
                </div>
                <div class="order-details row mb-2">
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <p class="m-0 text-start details-headings">Service Type</p>
                    {orderDetailsResp ? (
                      <p class="m-0 fw-600">
                        {orderDetailsResp?.serviceItemsList[0]?.service_name}
                      </p>
                    ) : (
                      <Skeleton height={15} />
                    )}
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <p class="m-0 details-headings">Service Center</p>
                    {orderDetailsResp ? (
                      <p class="m-0 fw-600">
                        {orderDetailsResp?.service_center_memberId}
                      </p>
                    ) : (
                      <Skeleton height={15} />
                    )}
                  </div>
                </div>
                <div class="order-details row ">
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <p class="m-0 text-start details-headings">Model</p>

                    {orderDetailsResp ? (
                      <p class="m-0 fw-600">
                        {orderDetailsResp?.vehicle_brand_name},{" "}
                        {orderDetailsResp?.vehicle_model_name}
                      </p>
                    ) : (
                      <Skeleton height={15} />
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <p class="m-0 details-headings">Arrival Type</p>
                    {orderDetailsResp ? (
                      <p class="m-0 fw-600">{orderDetailsResp?.arrivalType}</p>
                    ) : (
                      <Skeleton height={15} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div class=" ">
              <div class="mx-3">
                <h5 class="fw-600" style={{ fontSize: "17px" }}> Service Status</h5>
                {orderDetailsResp ? (
                  <div class="px-3 steps">
                    <div
                      className="progressBar"
                      style={{
                        width: `${orderDetailsResp?.orderStatus?.status === 7 && "75%"
                          }`,
                      }}
                    >
                      <div
                        className="progress"
                        id="progress"
                        style={{ width: progressBarWidth }}
                      ></div>
                      <div
                        className="progress-step progress-step1 progress-step-active"
                        data-title="Initiated"
                      ></div>
                      {orderDetailsResp?.orderStatus?.status !== 7 ? (
                        <>
                          <div
                            className={`progress-step progress-step2`}
                            data-title="Under Review & Service!"
                          ></div>
                          <div
                            className={`progress-step progress-step3`}
                            data-title="Ready for Delivery"
                          ></div>
                          <div
                            className={`progress-step progress-step4`}
                            data-title="Delivered"
                          ></div>
                        </>
                      ) : (
                        <>
                          <div
                            className={`progress-step progress-step5`}
                            data-title="Cancelled"
                          ></div>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    <Skeleton height={20} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showOrderEstimantion && (
        <>
          <OrderEstimation closeOrderEstimation={() => { setShowOrderEstimation(false) }} />
        </>
      )}
    </>
  );
};

export default OrderDetails;
