import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SkeletonLoader from "../../common/SkeletonLoaders/SkeletonLoaders";
import { setActiveModule } from "../../features/account/customerProfileSlice";
import { setIsNavigatedFromNotifications, setSelectedOrder } from "../../features/account/orderSlice";
import {
    getNotifications,
    markAsReadAll,
    notificationsSelector,
    notificationsUpdateAsRead
} from "../../features/notifications/notificationsSlice";
import CartRemainderNotifications from "./CartRemainderNotifications";
import OrderNotifications from "./OrderNotifications";
import PaymentNotifications from "./PaymentNotifications";

const Notifications = (props) => {
    /**
               *  1=>Order Related
                  2=>Cart Remainder
                  3=> Payment Refund
                  4=>coupons Info
               */

    const { closeModal } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [limit, setLimit] = useState(10);
    const [offSet, setOffSet] = useState(0);
    const [notifications, setNotifications] = useState([]);

    const { getNotificationResp, notificationsList,unReadCount } =
        useSelector(notificationsSelector);

    useEffect(() => {
        dispatch(getNotifications({ limit, offSet }));
    }, []);

    useEffect(() => {
        if (getNotificationResp) {
            if (notificationsList && notificationsList.length > 0) {
                const latestNotifications = notificationsList;
                markNotificationsAsRead(latestNotifications)
                setNotifications([...notifications, ...notificationsList]);
                setOffSet(offSet + 10);
            }
        }
    }, [getNotificationResp]);

    const loadData = () => {
        dispatch(getNotifications({ limit, offSet }));
    };

    const markNotificationsAsRead = (latestNotifications) => {
        const notificationIDs = [];
        latestNotifications.forEach((notification) => {
            notificationIDs.push(notification.notificationId);
        });
        dispatch(notificationsUpdateAsRead(notificationIDs))
    }

    const displayOrderDetails = (order) => {
        dispatch(setIsNavigatedFromNotifications(true));
        dispatch(setActiveModule("Order History"));
        navigate("/profile/order-history");
        dispatch(setSelectedOrder(order));
    }

    const markAsReadAllNotif = () => {
        dispatch(markAsReadAll())
        closeModal();
    }

    return (
        <>
            <div class="notifications">
                <Modal className="notification-modal" show={true}>
                    <Modal.Body
                        style={{
                            height: "fit-content",
                            //   borderRadius: "15px",
                        }}
                    >
                        <div>
                            <div class="d-flex align-items-center justify-content-between">
                                <p class="notifications-heading ">Notifications</p>
                               {unReadCount > 0 && <p class="markAsReadAll pointer" onClick={markAsReadAllNotif}>Mark as read all</p>}
                            </div>
                            {/* <div class="notifications-list"> */}
                            <InfiniteScroll
                                style={{ overflowX: "hidden" }}
                                dataLength={notifications?.length || 10} //This is important field to render the next data
                                next={(event) => loadData()}
                                hasMore={
                                    getNotificationResp?.data ===
                                        []
                                        ? false
                                        : true
                                }
                                height={400}
                                loader={<SkeletonLoader isSmallLoader={true} count={4} width={"100%"} />}
                            >
                                <div class="notifications-list">
                                    {notifications?.length > 0 &&
                                        notifications.map((notification) => {
                                            return (
                                                <>
                                                    {notification.notification_type === 1 && <OrderNotifications notification={notification} displayOrderDetails={displayOrderDetails} />}
                                                    {notification.notification_type === 2 && <CartRemainderNotifications notification={notification} />}
                                                    {notification.notification_type === 3 && <PaymentNotifications notification={notification} />}
                                                </>
                                            );
                                        })}
                                </div>
                            </InfiniteScroll>
                            {/* </div> */}
                        </div>
                    </Modal.Body>

                    <div class="close-modal d-flex justify-content-end">
                        <img
                            src="../assets/icons/cross.png"
                            style={{ width: "10%" }}
                            class="pointer"
                            onClick={() => {
                                closeModal();
                            }}
                        ></img>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default Notifications;
