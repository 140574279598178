import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { getDefaultMiddleware } from '@reduxjs/toolkit';
import addressReducer from "../features/account/addressSlice";
import authReducer from '../features/auth/authSlice';
import bannerReducer from '../features/banner/bannerSlice';
import cartReducer from '../features/cart/cartSlice';
import checkoutReducer from '../features/Checkout/checkoutslice';
import emergencyservicesReducer from '../features/emergency/emergencySlice';
import garagesReducer from '../features/garage/garageSlice';
import homeReducer from '../features/home/homeSlice';
import servicepackageReducer from '../features/servicepackage/servicepackageSlice';
import carspaservicesReducer from '../features/spa/spaserviceSlice';
import alertReducer from '../features/alerts/alertsSlice';
import couponsAndWalletReducer from '../features/couponsAndWallet/couponsAndWallet';
import vehicleReducer from "../features/account/vehicleSlice";
import walletReducer from "../features/account/walletSlice";
import customerProfileReducer from "../features/account/customerProfileSlice";
import orderHistoryReducer from '../features/account/orderSlice';
import termsAndConditionsReducer from "../features/cms/termsAndConditionsSlice";
import accountReducer from "../features/account/accountSlice";
import partnerReducer from "../features/partner/partnerSlice"
import outsidePaymentReducer from "../features/outsidePayments/outsidePaymentSlice";
import notificationReducer from "../features/notifications/notificationsSlice"
// Combining all reducers and initializing the store

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false
});

const combinedReducer = combineReducers({
  auth: authReducer,
  home: homeReducer,
  emergencyservices: emergencyservicesReducer,
  servicepackage: servicepackageReducer,
  carspaservices: carspaservicesReducer,
  banner: bannerReducer,
  garage: garagesReducer,
  address: addressReducer,
  checkout: checkoutReducer,
  cart: cartReducer,
  alerts: alertReducer,
  couponsAndWallet: couponsAndWalletReducer,
  vehicle: vehicleReducer,
  wallet: walletReducer,
  customerProfile: customerProfileReducer,
  order: orderHistoryReducer,
  termsAndConditions: termsAndConditionsReducer,
  partner: partnerReducer,
  outsidePayment: outsidePaymentReducer,
  account: accountReducer,
  notifications: notificationReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout/fulfilled') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: customizedMiddleware,
  devTools: process.env.NODE_ENV !== 'production'
});

export default store;
