import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonLoader = (props) => {
  const {
    isRectangle,
    isCardSkeleton,
    isSmallLoader,
    count,
    loopCount,
    width,
  } = props;

  const RectangularSkeleton = () => {
    return (
      <>
        {Array(count)
          .fill(0)
          .map((item) => {
            return (
              <div class="mb-5">
                <div class="row">
                  <div class="col-lg-3 col-md-3 col-sm-12 d-flex justify-content-around">
                    <Skeleton
                      height={80}
                      width={150}
                      style={{ borderRadius: "20px" }}
                    />
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-12">
                    <Skeleton count={4} />
                  </div>
                </div>
                <Skeleton count={2} />
              </div>
            );
          })}
      </>
    );
  };

  const CardSkeleton = () => {
    return (
      <>
        <div class="row">
          <div className="col-lg-10">
            <div class="d-flex justify-content-around">
              <Skeleton
                height={100}
                width={170}
                style={{ borderRadius: "20px", margin: "auto" }}
              />
            </div>
            <Skeleton count={5} height={10} />
          </div>
        </div>
      </>
    );
  };

  const SmallLoader = () => {
    return (
      <>
        <div class="row">
          {Array(loopCount)
            .fill(0)
            .map((item) => {
              return (
                <div style={{ width: width || "33%" }}>
                  <Skeleton count={count} height={100} />
                </div>
              );
            })}
        </div>
      </>
    );
  };

  return (
    <>
      {isRectangle && <RectangularSkeleton />}
      {isCardSkeleton && <CardSkeleton />}
      {isSmallLoader && <SmallLoader />}
    </>
  );
};

export default SkeletonLoader;
