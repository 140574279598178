import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Notifications } from "../../services/notification.service";
const initialState = {
    unReadCount: null,
    getUnreadCountResp: null,
    notificationsList: null,
    getNotificationResp: null,
    notificationsUpdateAsReadResp: null,
    markAsReadAllResp:null,
    isLoading:false
}


export const getUnreadCount = createAsyncThunk('notifications/getUnreadCount', async (data, thunkAPI) => {
    const notificationsService = new Notifications();
    try {
        const response = await notificationsService.getUnreadCount();
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const getNotifications = createAsyncThunk('notifications/getNotifications', async (data, thunkAPI) => {
    const notificationsService = new Notifications();
    try {
        const response = await notificationsService.getNotifications(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const notificationsUpdateAsRead = createAsyncThunk('notifications/notificationsUpdateAsRead', async (data, thunkAPI) => {
    const notificationsService = new Notifications();
    try {
        const response = await notificationsService.notificationsUpdateAsRead(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const markAsReadAll = createAsyncThunk('notifications/markAsReadAll', async (data, thunkAPI) => {
    const notificationsService = new Notifications();
    try {
        const response = await notificationsService.markAsReadAll();
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        reset: (state) => {
            state.notificationsList = null
            state.getNotificationResp = null
            state.markAsReadAllResp = null
        }

    },
    extraReducers: (builder) => {
        builder
        .addCase(getUnreadCount.pending,(state,action)=>{
            state.isLoading = true
        })
            .addCase(getUnreadCount.fulfilled, (state, action) => {
                state.isLoading = false
                if (action.payload) {
                    state.getUnreadCountResp = action.payload.data;
                    if (state.getUnreadCountResp?.statuscode === 200) {
                        state.unReadCount = state.getUnreadCountResp.data.totalUnreaded
                    } else {
                        getUnreadCount();
                    }
                }
            })
            .addCase(getNotifications.fulfilled, (state, action) => {
                if (action.payload) {
                    state.getNotificationResp = action.payload.data;
                    if (state.getNotificationResp?.statuscode === 200) {
                        state.notificationsList = state.getNotificationResp.data
                    }
                }
            })
            .addCase(notificationsUpdateAsRead.fulfilled, (state, action) => {
                if (action.payload) {
                    state.notificationsUpdateAsReadResp = action.payload.data;
                }
            })
            .addCase(markAsReadAll.pending,(state,action)=>{
                state.isLoading = true
            })
            .addCase(markAsReadAll.fulfilled, (state, action) => {
                state.isLoading = false
                if (action.payload) {
                    state.markAsReadAllResp = action.payload.data;
                }
            })
    }
})

export const { reset } = notificationsSlice.actions;
export const notificationsSelector = (state) => state.notifications;
export default notificationsSlice.reducer;