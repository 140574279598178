import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { OrderService } from '../../services/order.service';
import { STATUS_CODES } from '../../config/constants';
import { CommonServices } from '../../utils/commonServices';
const commonServices = new CommonServices()
const intialState = {
    isLoading: false,
    isError: false,
    getOrdersListResp: null,
    ordersList: null,
    isOrderDetailsLoading: false,
    orderDetailsResp: null,
    orderCancelResp: null,
    updateEstimationResp: null,
    selectedOrder: commonServices.getLocalStrg("MMX_SELECTED_ORDER_ID") || null,
    isNavigatedFromNotifications: false
};

export const getOrdersList = createAsyncThunk('order/getOrdersList', async (data, thunkAPI) => {
    const orderService = new OrderService()
    try {
        const response = await orderService.getOrdersList(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const getOrderDetails = createAsyncThunk('order/getOrderDetails', async (data, thunkAPI) => {
    const orderService = new OrderService()
    try {
        const response = await orderService.getOrderDetails(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const cancelOrder = createAsyncThunk('order/cancelOrder', async (data, thunkAPI) => {
    const orderService = new OrderService()
    try {
        const response = await orderService.cancelOrder(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const updateEstimation = createAsyncThunk('order/updateEstimation', async (data, thunkAPI) => {
    const orderService = new OrderService()
    try {
        const response = await orderService.updateEstimation(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});



const orderSlice = createSlice({
    name: 'order',
    initialState: intialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isError = false;
            state.orderCancelResp = null;
            state.ordersList = null;
            state.orderDetailsResp = null;
            state.isNavigatedFromNotifications = false
        },
        updateOrderDetails: (state, action) => {
            state.orderDetailsResp = action.payload;
        },
        setIsNavigatedFromNotifications: (state, action) => {
            state.isNavigatedFromNotifications = action.payload
        },
        setSelectedOrder: (state, action) => {
            state.selectedOrder = action.payload
        }
    },
    extraReducers: (builder) => {
        // add your async reducers here
        builder
            .addCase(getOrdersList.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getOrdersList.fulfilled, (state, action) => {
                state.isLoading = false;
                state.getOrdersListResp = action.payload;
                state.ordersList = action.payload.data
            })
            .addCase(getOrderDetails.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getOrderDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                state.orderDetailsResp = action.payload.data;
                // state.ordersList = action.payload.data
            })
            .addCase(cancelOrder.fulfilled, (state, action) => {
                if (action.payload.statuscode === STATUS_CODES[200]) {
                    state.orderCancelResp = action.payload
                }
            })
            .addCase(updateEstimation.fulfilled, (state, action) => {
                if (action.payload.statuscode === STATUS_CODES[200]) {
                    state.updateEstimationResp = action.payload
                }
            })
    }
});

export const { reset, updateOrderDetails, setSelectedOrder, setIsNavigatedFromNotifications } = orderSlice.actions;

export const orderSelector = (state) => state.order;

export default orderSlice.reducer;