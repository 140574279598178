import React, { useCallback, useEffect, useState } from 'react';
import Geocode from "react-geocode";
import { useGeolocated } from "react-geolocated";
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LoginOrRegistrationModalComponent } from '../../components/Login/Login';
import SelectAddress from '../../components/SelectAddress/SelectAddress';
import { ADDRESS_LIST, API_key, STATUS_CODES } from '../../config/constants';
import { addressSelector, setAddress, updateAddressTypeDesc } from '../../features/account/addressSlice';
import { setActiveModule } from '../../features/account/customerProfileSlice';
import { authSelector, logout } from '../../features/auth/authSlice';
import { cartSelector, getCartItems, updateCartCustomerId } from '../../features/cart/cartSlice';
import { homeselector, updateCloseMenuFlag } from '../../features/home/homeSlice';
import {
  getservices, servicepackageselector, updateServicePackagesList
} from "../../features/servicepackage/servicepackageSlice";
import { CommonServices } from '../../utils/commonServices';
import { HttpClient } from '../../utils/httpClient';
import "../Header/header.css";
import LocationChangeConfirmationModal from './LocationChangeConfirmationModal';
import { setIsAnyAccountModuleSelectedMobile } from '../../features/account/accountSlice';

const Header = () => {

  const { isAuthenticated, isLogoutSuccess } = useSelector(authSelector);
  const { servicesSearchKey } = useSelector(servicepackageselector)
  const { closeMenuflag } = useSelector(homeselector)
  const commonServices = new CommonServices()
  const httpClient = new HttpClient();
  const { addresstypedescr, selectedUserAddress, address } = useSelector(addressSelector)
  const { cartItemsCount, deleteCartResponse, cartDetails } = useSelector(cartSelector)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation()
  // const [, setAddress] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [openNav, setOpenNav] = useState(false)
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [searchKey, setSearchKey] = useState(null)
  const [showChangeLocationConfirmModal, setChangeLocationConfirmModal] = useState(false)
  const { coords } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  useEffect(() => {
    Geocode.setApiKey(API_key);
  }, [])

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (isLogoutSuccess) {
      navigate("/");
      window.location.reload()
    }
  }, [isLogoutSuccess])

  const selectAddress = () => {
    if (location.pathname !== "/services/service-centers" && location.pathname !== "/services/mycart" && location.pathname !== "/services/checkout") {
      setShowModal(true);
    }
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  useEffect(() => {
    if (coords && !isLogoutSuccess) {
      const selectedaddress =
        commonServices.getLocalStrgJSON('MMX_CRRADDRESS');
      if (isAuthenticated || selectedaddress) {     // if user is logged in or already having any selected address set that address
        getcustomerAddress();
      } else {
        getAddress(coords.latitude,
          coords.longitude);
      }
    }
    const cartId = commonServices.getLocalStrg("MMX_CURRENT_CARTID");
    if (cartId) {
      dispatch(getCartItems());
    }

  }, [coords])

  useEffect(() => {
    if (isAuthenticated) {
      setShowLoginModal(false)
      const cartId = commonServices.getLocalStrg("MMX_CURRENT_CARTID");
      if (cartId && cartDetails?.data?.customerId === null) {
        dispatch(updateCartCustomerId());
      }

    } else {
      dispatch(getCartItems());
    }
  }, [isAuthenticated])

  useEffect(() => {
    Geocode.setApiKey(API_key);
  }, [])

  const getcustomerAddress = () => {
    const selectedaddress =
      commonServices.getLocalStrgJSON('MMX_CRRADDRESS');
    if (selectedaddress != null) {
      let currentaddress = '';
      currentaddress = selectedaddress.Location;
      dispatch(setAddress(currentaddress))
      if (selectedaddress.AddressType === 1)
        dispatch(updateAddressTypeDesc("Home"));

      if (selectedaddress.AddressType === 2)
        dispatch(updateAddressTypeDesc("Work"));

      if (selectedaddress.AddressType === 3)
        dispatch(updateAddressTypeDesc('Family & Friends'));

      if (selectedaddress.AddressType === 4)
        dispatch(updateAddressTypeDesc(selectedaddress.SaveAs));

    }

    if (selectedaddress === null) {
      setCurrentLocation();
    }
  }

  const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
  }
  const getDistanceFromLatLonInKm = (lat_1, lon_1, lat_2, lon_2) => {
    let R = 6371;
    let dLat = deg2rad(lat_2 - lat_1);
    let dLon = deg2rad(lon_2 - lon_1);
    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat_1)) *
      Math.cos(deg2rad(lat_2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c; // Distance in km
    return d ? d.toFixed(1) : d;
  }

  const setCurrentLocation = () => {
    httpClient
      .post(ADDRESS_LIST)
      .then((resp) => {
        if (resp.status === STATUS_CODES[200]) {
          if (resp.data.data.length === 0) {
            getAddress(coords.latitude, coords.longitude);
          } else {
            resp.data.data.forEach((address) => {
              if (address.AddressType === 1)
                address.addresstypedescr = 'Home';
              if (address.AddressType === 2)
                address.addresstypedescr = 'Work';
              if (address.AddressType === 3)
                address.addresstypedescr = 'Family & Friends';
              if (address.AddressType === 4)
                address.addresstypedescr = address.SaveAs;
              const distance = getDistanceFromLatLonInKm(
                address.Latitude,
                address.Longitude,
                coords.latitude,
                coords.longitude
              );
              if (distance < 0.02) {
                dispatch(updateAddressTypeDesc(address))
                commonServices.setLocalStrg(
                  'MMX_CRRADDRESS',
                  JSON.stringify(address)
                );
                let currentaddress = address.Address1;
                if (
                  address.Address2 !== null &&
                  address.Address2 !== '' &&
                  address.Address2 !== undefined
                )
                  currentaddress += ' ,' + address.Address2;
                if (
                  address.Address3 !== null &&
                  address.Address3 !== '' &&
                  address.Address3 !== undefined
                )
                  currentaddress += ' ,' + address.Address3;

                currentaddress += ' ,' + address.Location;
                address = currentaddress;
                dispatch(updateAddressTypeDesc(address.addresstypedescr));

                commonServices.setLocalStrg(
                  'MMX_ADDRESS',
                  currentaddress
                );
                commonServices.setLocalStrg(
                  'MMX_ADDRESSID',
                  address._id
                );
                commonServices.setUserLocation({
                  lat: address.Latitude,
                  lng: address.Longitude,
                });
                commonServices.setLocalStrg(
                  'MMX_USER_LATLON',
                  JSON.stringify({
                    lat: address.Latitude,
                    lng: address.Longitude,
                  })
                );
                commonServices.setLocalStrg("MMX_USER_PINCODE", address.Pincode)
                commonServices.setLocalStrg(
                  'MMX_ISADDRESSSAVED',
                  'true'
                );
              } else {
                getAddress(coords.latitude,
                  coords.longitude);
              }
            });
          }
        }
      })
      .catch((error) => {
        window.location.reload()
        // console.log("error::", error)
      });
  }

  const getAddress = (latitude, longitude) => {
    commonServices.setUserLocation({
      lat: latitude,
      lng: longitude,
    });

    Geocode.fromLatLng(latitude, longitude).then(
      (response) => {
        let address1 = '';
        let address2 = "";

        if (response.status === "OK") {
          if (response.results.length > 0) {
            response.results.forEach((element) => {
              element.types.forEach((s) => {
                if (s === 'locality' && address1 === '') {
                  address1 = element.formatted_address;
                }
                if (s === 'sublocality_level_1') {
                  address2 = element.formatted_address
                  // setAddress(element.formatted_address)
                }
                if (s === 'sublocality_level_2') {

                  dispatch(updateAddressTypeDesc(element?.address_components[0]?.long_name))
                }
                if (s === 'postal_code') {
                  let postal_code = element.address_components[0].long_name;
                  commonServices.setLocalStrg("MMX_USER_PINCODE", postal_code)
                }
              });
            });
            commonServices.setLocalStrg("MMX_USER_LATLON", JSON.stringify({ lat: latitude, lng: longitude }));
            commonServices.setLocalStrg("MMX_ISADDRESSSAVED", "false");
            dispatch(setAddress(address2 ? address2 : address1));
          } else {
            commonServices.consoleError(
              'Error while getting google place results',
              response.results
            );
          }
        } else {
          commonServices.consoleError(
            'Error while getting google place',
            response.status
          );
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }
  useEffect(() => {
    if (addresstypedescr === undefined || addresstypedescr === null || addresstypedescr === "") {
      dispatch(updateAddressTypeDesc(address?.split(',')[0]))
    }
  }, [address])

  useEffect(() => {
    if (address && addresstypedescr) {
      const finalAdd = address;
      commonServices.setLocalStrg('MMX_ADDRESS', finalAdd);
      const currentaddress = {
        Location: address,
        Latitude: commonServices.getLocalStrgJSON("MMX_USER_LATLON")?.lat,
        Longitude: commonServices.getLocalStrgJSON("MMX_USER_LATLON")?.lng,
        AddressType: 4,
        SaveAs: addresstypedescr
      }
      commonServices.setLocalStrg("MMX_CRRADDRESS", JSON.stringify(currentaddress));
      // dispatch(setUserSelectedAddress(finalAdd))
    }
  }, [address, addresstypedescr])

  const handleCallback = (childData) => {
    // setCurrentLocation();
    setShowModal(false)
    getAddress(coords.latitude,
      coords.longitude);
  }

  const handleServices = () => {
    dispatch(getCartItems());
  }

  const handleServicesSearch = (event) => {
    dispatch(updateServicePackagesList(null))
    setSearchKey(event.target.value)
    dispatch(getservices({ value: event.target.value }))
  }

  useEffect(() => {
    setSearchKey('')
  }, [servicesSearchKey])

  useEffect(() => {
    if (closeMenuflag) {
      setOpenNav(false);
      dispatch(updateCloseMenuFlag(false))
    }
  }, [closeMenuflag])

  useEffect(() => {
    if (deleteCartResponse && deleteCartResponse.statuscode === STATUS_CODES[200]) {
      dispatch(getCartItems())
    }
  }, [deleteCartResponse])

  const handleAccountModulesNavigation = (path, accontItem) => {
    navigate(path);
    setOpenNav(false);
    dispatch(setIsAnyAccountModuleSelectedMobile(true))
    dispatch(setActiveModule(accontItem));
  }
  return (
    <div className="header">
      <header className="fixed-top">
        <nav className="navbar navbar-expand-lg ">
          <div className="container-fluid">
            <OutsideClickHandler onOutsideClick={() => {
              setOpenNav(false)
            }}>


              <button className="navbar-toggler" type="button" onClick={() => setOpenNav(!openNav)}>
                <span className="navbar-toggler-icon">
                  <div />
                  <div />
                  <div />
                </span>
              </button>
            </OutsideClickHandler>

            {/* Side Menu */}
            <div id="mySidenav" class="sidenav" style={{ width: `${openNav ? "277px" : "0px"}` }} >
              <div class="p-3 d-flex align-items-center justify-content-between">
                <Link to={"/home"} className="navbar-brand logo">
                  <img src="../assets/imgs/logo/metamechx-logo-gw.svg" alt="" class="img-fluid" />
                  <span class="green_text logo_name">Meta</span><span style={{ color: "white" }} class="logo_name">Mech</span><span
                    class="green_text logo_name">X</span>
                  <div><p class="logo_tagline m-0">YOUR MECHANIC EVERYWHERE</p>
                  </div>
                </Link>
                <a href="javascript:void(0)" class="closebtn" onClick={() => setOpenNav(false)}>&times;</a>
              </div>
              {isAuthenticated && <h6 class=" text-start" style={{ marginLeft: "5%" }}><span class="text-white" >Hi</span> <span style={{ color: "#379B63" }}>{commonServices.getLocalStrgJSON("MMX_USER")?.full_name} !</span></h6>}
              <div class="menu-items">
                <a href="#" onClick={() => { navigate("/home"); setOpenNav(false) }}><i class="fas fa-home"></i> &nbsp; Home</a>

                <a class="dropbtn" onClick={() => { navigate("/about"); setOpenNav(false) }}><i class="fas fa-users p-0"></i>  {" "} &nbsp; About Us
                </a>



                <a href="#" onClick={() => { navigate("/services"); setOpenNav(false); handleServices() }}><i class="fab fa-servicestack"></i> &nbsp; Services</a>
                <a href="#" onClick={() => { navigate("/partners"); setOpenNav(false) }}> <i class="fas fa-handshake p-0"></i> &nbsp; Partners</a>
                {!isAuthenticated && <a href="#" type="submit" onClick={() => { setShowLoginModal(true); setOpenNav(false) }}> <i class="fas fa-gift"></i> &nbsp; Login</a>}
                {isAuthenticated && (
                  <>
                    <a href="#" onClick={() => { handleAccountModulesNavigation("/profile", "Profile Details"); }}> <i class="fas fa-user"></i> &nbsp; My Profile</a>
                    <a href="#" onClick={() => { handleAccountModulesNavigation("/profile/wallet", "MMX Wallet"); }}> <i class="fas fa-wallet"></i> &nbsp; My Wallet</a>
                    <a href="#" onClick={() => { handleAccountModulesNavigation("/profile/myaddresses", "Saved Addresses"); }}> <i class="fas fa-address-book"></i> &nbsp; My Addresses</a>
                    <a href="#" onClick={() => { handleAccountModulesNavigation("/profile/myvehicles", "My Vehicles"); }}> <i class="fas fa-car"></i> &nbsp; My Vehicles</a>

                    <a href="#" onClick={() => { handleAccountModulesNavigation("/profile/order-history", "Order History"); }}> <img src="/assets/imgs/account/historyIcon.png" alt="" width={"10%"} /> &nbsp; My Orders</a>
                    <a href="#" onClick={() => { logOut(); setOpenNav(false) }}> <i class="fas fa-sign-out-alt"></i> &nbsp; Logout</a>
                  </>
                )}
              </div>
            </div>

            {/* Metamechx Logo */}

            <Link to={"/home"} className="navbar-brand logo" onClick={() => window.scrollTo(0, 0)}>
              <img src="../assets/imgs/logo/metamechx-logo-gw.svg" alt="" class="img-fluid" />
              <span class="green_text logo_name">Meta</span><span style={{ color: "white" }} class="logo_name">Mech</span><span
                class="green_text logo_name">X</span>
              <div><p class="logo_tagline m-0">YOUR MECHANIC EVERYWHERE</p>
              </div>
            </Link>

            {/* Location */}

            <div class="address d-flex" onClick={() => { selectAddress() }}>
              <i class="fas fa-map-marker-alt fa-2x "></i>
              <div class="w-100">
                <p class="selected address-desc  common_color text-truncate  ">{addresstypedescr}</p>
                <p class="m-0  text-truncate location">{address}</p>
              </div>
            </div>

            {/* Search Bar */}

            <div class="search_bar">
              <i class="fas fa-search"></i>

              <Link to={"/services"}>
                <input type="text" placeholder="Search" value={searchKey || ""} onChange={(event) => {
                  handleServicesSearch(event)
                }} />
              </Link>
            </div>

            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav selected ms-auto  mb-2 mb-lg-0 align-items-center">

                <li className="nav-item " onClick={() => { setSearchKey(''); window.scrollTo(0, 0) }}>
                  <Link to={"/home"} className="nav-link">
                    Home
                  </Link>
                </li>
                <li className="nav-item " onClick={() => { setSearchKey(''); window.scrollTo(0, 0) }}>
                  <Link to={"/about"} className="nav-link">
                    About
                  </Link>
                </li>

                <li className="nav-item  " onClick={() => {
                  { setSearchKey(''); window.scrollTo(0, 0) }
                  handleServices()
                }}>
                  <Link to={"/services"} className="nav-link" >
                    Services
                  </Link>
                </li>

                <li className="nav-item  " onClick={() => { setSearchKey(''); window.scrollTo(0, 0) }}>
                  <Link to={"/partners"} className="nav-link" >
                    Partners
                  </Link>
                </li>

                <li className="nav-item  " onClick={() => setSearchKey('')}>
                  <div class="position-relative">
                    <Link to={"/services/mycart"} className="nav-link" >
                      <i class="fas fa-shopping-cart"></i>
                    </Link>

                    {cartItemsCount > 0 && <span
                      class="cart-count"
                    >{cartItemsCount}</span>}
                  </div>
                </li>

                {!isAuthenticated ?
                  <button className="btn-header" onClick={() => { setShowLoginModal(true) }} type="submit">Log In</button> :
                  (
                    <div className="navbar-nav ml-auto">
                      <li className="nav-item pt-2">
                      </li>
                      <li className="nav-item dropdown user-account">
                        <a className="nav-link dropdown-toggle" href="#" id="myAccountDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => setSearchKey('')}>
                          <i class="fas fa-user-circle fa-2x"></i>
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="myAccountDropdown">
                          <li onClick={() => {
                            dispatch(setActiveModule("Profile Details"));
                          }}><Link className="dropdown-item" to="/profile">Profile</Link></li>

                          <li onClick={() => {
                            dispatch(setActiveModule("MMX Wallet"));
                          }}><Link className="dropdown-item" to="/profile/wallet">My wallet</Link></li>

                          <li onClick={() => {
                            dispatch(setActiveModule("My Vehicles"));
                          }}><Link className="dropdown-item" to="/profile/myvehicles">My vehicles</Link></li>

                          <li onClick={() => {
                            dispatch(setActiveModule("Saved Addresses"));
                          }}><Link className="dropdown-item" to="/profile/myaddresses">Manage Addresses</Link></li>

                          <li onClick={() => {
                            dispatch(setActiveModule("Order History"));
                          }}><Link className="dropdown-item" to="/profile/order-history">Order History</Link></li>

                          <li onClick={() => {
                            dispatch(setActiveModule("Refer and Earn"));
                          }}><Link className="dropdown-item" to="/profile/refer&earn">Refer and earn</Link></li>

                          <li ><Link className="dropdown-item" to="#" onClick={logOut}>Logout</Link></li>
                        </ul>
                      </li>
                    </div>
                  )
                }
              </ul>
            </div>
          </div>
        </nav>
      </header>
      {showLoginModal && <LoginOrRegistrationModalComponent
        show={showLoginModal}
        setShow={() => {
          setShowLoginModal(false);
        }}
      />}
      {showChangeLocationConfirmModal && <LocationChangeConfirmationModal show={showChangeLocationConfirmModal} closeModal={() => { setChangeLocationConfirmModal(false) }} />}
      {showModal && (<SelectAddress showModal={showModal}
        handleClose={() => { handleCloseModal() }}
        parentCallback={handleCallback}
        getAddress={(lat, lng) => getAddress(lat, lng)}
        setAddress={(address) => dispatch(setAddress(address))}
        showChangeLocationConfirmationModal={() => { setChangeLocationConfirmModal(true) }}
      />)}
    </div>
  );
};

export default Header;