import { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-autocomplete-places";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Geocode from "react-geocode";
import { useGeolocated } from "react-geolocated";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import GoogleMaps from "../../common/GoogleMaps/GoogleMaps";
import { API_key } from "../../config/constants";
import {
  addressSelector,
  createAddress,
  getAddressesList,
  removeAddress,
  reset,
  setAddress as setAddressAction,
  updateAddress,
  updateAddressTypeDesc
} from "../../features/account/addressSlice";
import { deleteCart } from "../../features/cart/cartSlice";
import { CommonServices } from "../../utils/commonServices";
import DisplayMySavedAddresses from "./DisplayMySavedAddresses";
import "./MyAddresses.css";

const MyAddresses = () => {
  const [myAddressesList, setMyAddressesList] = useState([]);
  const [isAddAddressTrue, setIsAddAddressTrue] = useState(false);
  const [statesList, setStatesList] = useState([]);
  const [fullName, setFullName] = useState("");
  const [mobileNumber, setMobileNumber] = useState(null);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [landmark, setLandmark] = useState("");
  const [pincode, setPincode] = useState(null);
  const [town, setTown] = useState("");
  const [state, setState] = useState("");
  const [addressType, setAddressType] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [userAddress, setUserAddress] = useState({});
  const [defaultAddress, setDefaultAddress] = useState({});
  const [savedefaultAddress, setSaveDefaultAddress] = useState({});
  const [isError, setIsError] = useState(false);
  const commonServices = new CommonServices();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [location, setLocation] = useState({});
  const [address, setAddress] = useState({});
  const [searchValue, setSearchValue] = useState(" ");
  const {
    addressList,
    createAddressResponse,
    updateAddressResp,
    removeAddressResp,
  } = useSelector(addressSelector);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [selectedDefaultAddress, setSelectedDefaultAddress] = useState({});
  const [customerAddressId, setCustomerAddressId] = useState(null);
  const [saveAs, setSaveAs] = useState("");
  const [enableSaveAs, setEnableSaveAs] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteData, setDeleteData] = useState({});

  const handleClose = () => {
    setShowDeleteConfirmation(false);
    setShowConfirmationDialog(false);
  };
  const handleShow = () => setShowConfirmationDialog(true);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAddressesList());
    setStatesList(commonServices.states());
  }, []);

  useEffect(() => {
    if (addressList) {
      const newArray = addressList.map((ele) => {
        if (ele.AddressType === 4) {
          let newItem = {
            ...ele,
            addressName: ele.SaveAs ? ele.SaveAs : "Other",
          };
          return newItem;
        } else if (ele.AddressType === 1) {
          let newItem = { ...ele, addressName: "Home" };
          return newItem;
        } else if (ele.AddressType === 2) {
          let newItem = { ...ele, addressName: "Work" };
          return newItem;
        } else {
          let newItem = { ...ele, addressName: "Family & Friends" };
          return newItem;
        }
      });
      setMyAddressesList(newArray);
    }
  }, [addressList]);

  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 500px) and (max-width: 912px)",
  });
  const isLaptopOrDesktop = useMediaQuery({
    query: "(min-width: 1008px) and (max-width: 1824px)",
  });

  const handleSubmit = () => {
    const expr = /^[6-9]{1}[0-9]{9}$/;
    setIsError(false);
    // setIsAddAddressTrue(false);
    window.scroll(0, 0);

    setDefaultAddress({
      country: "India",
      address1,
      address2,
      landmark,
      pincode,
      town,
      state,
      addressType,
    });
    if (isEditEnabled) {
      dispatch(
        updateAddress({
          country: "India",
          fullName,
          mobileNumber,
          address1,
          address2,
          landmark,
          pincode,
          town,
          state,
          addressType,
          customerAddressId,
          saveAs,
          latitude,
          longitude,
        })
      );
      setIsAddAddressTrue(false);
    } else {
      if (pincode !== undefined && pincode !== null && pincode !== "") {
        dispatch(
          createAddress({
            country: "India",
            fullName,
            mobileNumber,
            address1,
            address2,
            landmark,
            pincode,
            town,
            state,
            addressType,
            saveAs,
            latitude,
            longitude,
          })
        );
        setIsAddAddressTrue(false);
      } else {
        toast.warning("Please Select your address", { toastId: "select address", autoClose: 2000, hideProgressBar: true })
      }
    }

    setAddress1("");
    setAddress2("");
    setLandmark("");
    setState("");
    setTown("");
    setPincode("");
    setAddressType("");
    setSaveAs("");
    setLatitude("");
    setLongitude("");
  };

  const handleMarkerDrag = async (event) => {
    const latitude = event.latLng.lat();
    const longitude = event.latLng.lng();

    setLatitude(latitude);
    setLongitude(longitude);

    setLocation({
      address: "",
      lat: latitude,
      lng: longitude,
    });

    const response = await Geocode.fromLatLng(latitude, longitude);

    let addresstypedescr = "";
    let postCode = "";

    if (response.status === "OK") {
      if (response.results.length > 0) {
        response.results.forEach((element, i) => {
          if (i === 0) {
          }
          element.types.forEach((s) => {
            if (s === "locality" && address1 === "") {
              setAddress2(element.formatted_address);
            }
            if (s === "sublocality_level_1") {
              setAddress1(element.formatted_address);
              // setAddress(element.formatted_address)
            }
            if (s === "postal_code") {
              postCode = element.address_components[0].long_name;
              setPincode(postCode);
              setState(element.address_components[3].long_name);
              setTown(element.address_components[1].long_name);
            }
          });
        });
      }
    }
  };

  useEffect(() => {
    Geocode.setApiKey(API_key);
  }, []);

  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  });

  useEffect(() => {
    const address = commonServices.getLocalStrgJSON("MMX_CRRADDRESS");
    if (address) {
      setLocation({
        address: "",
        lat: address.Latitude,
        lng: address.Longitude,
      });
    }
  }, [coords]);

  useEffect(() => {
    if (createAddressResponse) {
      toast.success(createAddressResponse.message, {
        toastId: "createAddress",
        autoClose: 2000,
        hideProgressBar: true,
      });
      dispatch(reset());
      dispatch(getAddressesList());
      setEnableSaveAs(false);
    }
  }, [createAddressResponse]);

  useEffect(() => {
    if (updateAddressResp) {
      setIsEditEnabled(false);
      toast.success(updateAddressResp.message, {
        toastId: "updateAddress",
        autoClose: 2000,
        hideProgressBar: true,
      });
      dispatch(reset());
      dispatch(getAddressesList());
    }
  }, [updateAddressResp]);

  useEffect(() => {
    if (removeAddressResp) {
      setShowDeleteConfirmation(false);
      toast.success("Address removed successfully", {
        toastId: "removeAddress",
        autoClose: 2000,
        hideProgressBar: true,
      });
      dispatch(reset());
      dispatch(getAddressesList());
    }
  });

  const handleChange = (address) => {
    setSearchValue(address);
  };

  const handleSelect = (address) => {
    geocodeByAddress(address).then(async (results) => {
      results[0].address_components.forEach((ele) => {
        if (ele.types[0] === "postal_code") {
          setPincode(ele.long_name);
        } else if (ele.types[0] === "administrative_area_level_1") {
          setState(ele.long_name);
        } else if (ele.types[0] === "locality") {
          setTown(ele.long_name);
        }
      });
      setAddress2(
        results[0].address_components[0].long_name +
        "," +
        results[0].address_components[1].long_name
      );
      const latlng = await getLatLng(results[0]);
      setLatitude(latlng.lat);
      setLongitude(latlng.lng);
      setLocation({
        address: "",
        lat: latlng.lat,
        lng: latlng.lng,
      });
    });
    setSearchValue("");
  };

  const handleDelete = (data) => {
    dispatch(removeAddress(data));
    dispatch(getAddressesList());
  };

  const handleEdit = (data) => {
    setIsEditEnabled(true);
    setIsAddAddressTrue(true);
    setAddress1(data.Address1);
    setAddress2(data.Address2);
    setPincode(data.PinCode);
    setTown(data.City);
    setState(data.State);
    setCustomerAddressId(data.customerAddressId);
    setAddressType(data.AddressType);
    if (data.AddressType === 4) {
      setEnableSaveAs(true);
      setSaveAs(data.SaveAs && data.SaveAs);
    }
    if (data.Latitude && data.Longitude) {
      setLatitude(data.Latitude);
      setLongitude(data.Longitude);
      setLocation({
        address: "",
        lat: data.Latitude,
        lng: data.Longitude,
      });
    }
  };

  const handleYes = () => {
    handleClose();
    let addressTypeDesc = "";

    if (selectedDefaultAddress.AddressType === 1) {
      addressTypeDesc = "Home"
    } else if (selectedDefaultAddress.AddressType === 2) {
      addressTypeDesc = "Work";
    } else if (selectedDefaultAddress.AddressType === 3) {
      addressTypeDesc = "Friends & Family";
    } else {
      addressTypeDesc = selectedDefaultAddress.SaveAs
        ? selectedDefaultAddress.SaveAs
        : "Other";
    }
    dispatch(setAddressAction(selectedDefaultAddress.Location));
    dispatch(updateAddressTypeDesc(addressTypeDesc));

    validateLocationChange(selectedDefaultAddress.Latitude, selectedDefaultAddress.Longitude)
    commonServices.setLocalStrg("MMX_ADDRESS", selectedDefaultAddress.Location);
    commonServices.setLocalStrg("MMX_ADDRESSID", selectedDefaultAddress.customerAddressId);
    commonServices.setLocalStrg("MMX_USER_LATLON", JSON.stringify({ lng: selectedDefaultAddress.Longitude, lat: selectedDefaultAddress.Latitude }))
    commonServices.setLocalStrg("MMX_CRRADDRESS", JSON.stringify(selectedDefaultAddress));
    commonServices.setLocalStrg("MMX_USER_PINCODE", selectedDefaultAddress.PinCode);
    commonServices.setLocalStrg("MMX_ISADDRESSSAVED", "true");
  };

  const handleSetDefault = (data) => {
    setShowConfirmationDialog(true);
    setSelectedDefaultAddress(data);
  };

  const validateLocationChange = (lat, lng) => {
    const currentCartId = commonServices.getLocalStrg("MMX_CURRENT_CARTID")
    const currentLatLng = commonServices.getLocalStrgJSON("MMX_USER_LATLON")
    if (currentCartId) {
      if (currentLatLng && currentLatLng.lat !== lat && currentLatLng.lng !== lng) {
        dispatch(deleteCart(currentCartId))
        localStorage.removeItem("MMX_CURRENT_CARTID");
        localStorage.removeItem("MMX_CURRENT_CART_SERVICECENTER");
        localStorage.removeItem("MMX_SERVICE_CENTER");
      }
    }
  }
  const deleteValues = () => {
    setAddress1("");
    setAddress2("");
    setLandmark("");
    setState("");
    setTown("");
    setPincode("");
    setAddressType("");
    setSaveAs("");
    setLatitude("");
    setLongitude("");
  };

  const DeleteConfirmationBox = () => {
    return (
      <>
        <Modal show={showDeleteConfirmation} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>Do you want to remove this Address?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button
              onClick={() => {
                handleDelete(deleteData);
                setShowDeleteConfirmation(false);
              }}
              class="addressBtns"
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const DialogBox = () => {
    return (
      <>
        <Modal show={showConfirmationDialog} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body> Changing location will empty your cart(If Any).<br />Do you want to set this as "Default Address"?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button onClick={() => handleYes()} class="addressBtns">
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };
  return (
    <>
      <DialogBox />
      <DeleteConfirmationBox />
      <div class="my_addresses myAddressesPage">
        <div class="row  border_radius">
          <div class="col-lg-9 col-md-8 col-sm-12">
            <h5>My Addresses</h5>
            <p>An overview of your addresses listed add or remove more</p>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-8">
            {!isAddAddressTrue && (
              <div>
                <button
                  class="border-0 common-btn px-2 py-1 add-address-btn float-end"
                  onClick={() => {
                    setIsAddAddressTrue(true);
                  }}
                  style={{ fontSize: "15px" }}
                >
                  <i class="fas fa-plus pe-2"></i>
                  Add address
                </button>
              </div>
            )}
          </div>
          {isAddAddressTrue && (
            <>
              <div class="col-sm-12 p-0">
                <div class="row address-form d-flex align-items-center justify-content-between">
                  <div style={{ marginBottom: "2rem" }}>
                    <div
                      class="searchBox row"
                      style={{ display: "flex", marginBottom: "2rem" }}
                    >
                      <label class="col-lg-3 m-0">Search Address:</label>
                      <PlacesAutocomplete
                        value={searchValue}
                        onChange={handleChange}
                        onSelect={handleSelect}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div class="col-lg-9 col-sm-10">
                            <input
                              {...getInputProps({
                                placeholder: "Search for area, street name..",
                                className: "location-search-input",
                              })}
                              style={{ width: "100%", marginBottom: "0.5rem" }}
                              value={searchValue}
                            />
                            <div className="autocomplete-dropdown-container dropDownBox">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                    backgroundColor: "#d8f9e4",
                                    cursor: "pointer",
                                    padding: "5px 0px 10px 0px",
                                    fontSize: "12px",
                                    borderRadius: "5px",
                                  }
                                  : {
                                    cursor: "pointer",
                                    padding: "5px 0px 10px 0px",
                                    fontSize: "12px",
                                  };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <div class="d-flex align-items-center">
                                      <i class="fas fa-location-arrow"></i>
                                      <p class="m-0 main-text">
                                        {
                                          suggestion?.formattedSuggestion
                                            ?.mainText
                                        }
                                      </p>
                                    </div>
                                    <p class="m-0 secondary-text w-100 text-truncate">
                                      {
                                        suggestion?.formattedSuggestion
                                          ?.secondaryText
                                      }
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                    <div className="col-sm-11 col-md-12 col-lg-12">
                      <GoogleMaps
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_key}&v=3.exp&libraries=geometry,drawing,places`}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `400px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        location={location}
                        markerDrag={handleMarkerDrag}
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6">
                      <div className="row address-box mb-3">
                        <div class="col-lg-4 col-sm-3">
                          <span class="add-address-labels">Address</span>
                        </div>
                        <div class="col-lg-8 col-md-9 col-sm-8">
                          <input
                            type="text"
                            class="w-100 p-1 ps-3 rounded-3 common_border addressWidth"
                            placeholder="Flat, House no., Building..."
                            value={address1 && address1}
                            onChange={(e) => {
                              setAddress1(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="row address-box  mb-3">
                        <div class="col-lg-4 col-sm-3">
                          <span></span>
                        </div>
                        <div class="col-lg-8 col-md-9 col-sm-8">
                          <input
                            type="text"
                            class="w-100 p-1 ps-3 rounded-3 common_border addressWidth"
                            placeholder="Area, Street, Sector, Village"
                            value={address2 && address2}
                            onChange={(e) => {
                              setAddress2(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 ">
                      <div className="row address-box  mb-3">
                        <div class="col-lg-4 col-sm-3 text-start">
                          <span class="add-address-labels">Landmark</span>
                        </div>
                        <div class="col-lg-8 col-md-9 col-sm-8">
                          <input
                            type="text"
                            class="w-100 p-1 ps-3 rounded-3 common_border addressWidth"
                            placeholder="eg. Near bus stand"
                            value={landmark && landmark}
                            onChange={(e) => {
                              setLandmark(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="row address-box  mb-3">
                        <div class="col-lg-4 col-sm-3 text-start">
                          <span class="add-address-labels">Pincode</span>
                        </div>
                        <div class="col-lg-8 col-md-9 col-sm-8">
                          <input
                            type="text"
                            class="w-100 p-1 ps-3 rounded-3 common_border addressWidth"
                            placeholder="Enter pincode here.."
                            value={pincode && pincode}
                            onChange={(e) => {
                              setPincode(e.target.value);
                            }}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-6">
                      <div className="row address-box mb-3">
                        <div class="col-lg-4 col-sm-3">
                          <span class="add-address-labels">Town/City</span>
                        </div>
                        <div class="col-lg-8 col-md-9 col-sm-8">
                          <input
                            type="text"
                            class="w-100 p-1 ps-3 rounded-3 common_border addressWidth"
                            placeholder="eg. Hyderabad"
                            value={town && town}
                            onChange={(e) => {
                              setTown(e.target.value);
                            }}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="row address-box mb-3">
                        <div class="col-lg-4 col-sm-3  text-start">
                          <span class="add-address-labels">State</span>
                        </div>
                        <div class="col-lg-8 col-md-9 col-sm-8">
                          <input
                            type="text"
                            class="w-100 p-1 ps-3 rounded-3 common_border addressWidth"
                            placeholder={state ? state : "State"}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-lg-3 col-md-3">
                      <span class="add-address-labels">Address Type</span>
                    </div>
                    <div class="col-lg-9 col-md-9 row">
                      <div class="col-sm-12 col-md-6">
                        <input
                          type="radio"
                          name="addressType"
                          class="common_border form-check-input"
                          id="Home"
                          onChange={(e) => {
                            setAddressType(1);
                            setEnableSaveAs(false);
                            setSaveAs("");
                          }}
                          value={addressType?.addressType}
                          checked={addressType === 1}
                        />
                        <label class="mx-1">Home</label>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <input
                          type="radio"
                          name="addressType"
                          class="common_border form-check-input"
                          id="Work"
                          onChange={(e) => {
                            setAddressType(2);
                            setEnableSaveAs(false);
                            setSaveAs("");
                          }}
                          checked={addressType === 2}
                        />
                        <label class="mx-1">Work</label>
                      </div>
                      <div class="col-md-6">
                        <input
                          type="radio"
                          name="addressType"
                          class="common_border form-check-input"
                          id="friendsFamily"
                          onChange={(e) => {
                            setAddressType(3);
                            setEnableSaveAs(false);
                          }}
                          checked={addressType === 3}
                        />
                        <label class="mx-1">Friends & Family</label>
                      </div>
                      <div class="col-md-6">
                        <input
                          type="radio"
                          name="addressType"
                          class="common_border form-check-input"
                          id="Other"
                          onChange={(e) => {
                            setAddressType(4);
                            setEnableSaveAs(true);
                            setSaveAs("");
                          }}
                          checked={addressType === 4}
                        />
                        <label class="mx-1">Other</label>
                      </div>
                    </div>
                    <br />
                    {enableSaveAs && (
                      <div class="col-lg-6">
                        <div className="row address-box mb-3">
                          <div class="col-lg-4 col-sm-3 text-start">
                            <span class="add-address-labels">Save As</span>
                          </div>
                          <div class="col-lg-8 col-sm-9">
                            <input
                              type="text"
                              class="p-1 ps-3 rounded-3 common_border addressWidth"
                              placeholder=""
                              value={saveAs && saveAs}
                              onChange={(e) => {
                                setSaveAs(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div class="row">
                    {/* <div className="col-lg-6"></div> */}
                    <div className="col-lg-12 d-flex justify-content-center">
                      <div class="">
                        <button
                          class="border-0 address-save-btn px-3 py-1 mx-3  float-end "
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          <i class="fas fa-address-book pe-2 "></i>
                          Save
                        </button>
                      </div>
                      {/* <div class="col-lg-1 col-md-1 col-sm-1"></div> */}
                      <div class="">
                        <button
                          class="border-0 common-btn px-3 py-1 mx-3 float-end cancelBtn"
                          onClick={() => {
                            setIsAddAddressTrue(false);
                            window.scroll(0, 0);
                            deleteValues();
                          }}
                        >
                          <i class="fa fa-times" aria-hidden="true"></i> &nbsp;
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {myAddressesList.length == 0 ? (
          <div class="text-center mt-3 p-3 common_border border_radius">
            <>
              <h6>No address added</h6>
              <p>Add to see list of Address</p>
            </>
          </div>
        ) : (
          <>
            <h5 class="text-start">Saved Addresses</h5>
            <div class="savedAddress row justify-content-between">
              <DisplayMySavedAddresses
                myAddressesList={myAddressesList}
                handleDelete={(data) => {
                  setShowDeleteConfirmation(true);
                  setDeleteData(data);
                }}
                handleEdit={(data) => handleEdit(data)}
                handleSetDefault={(data) => handleSetDefault(data)}
                defaultAddress={commonServices.getLocalStrg("MMX_ADDRESSID")}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MyAddresses;
