import { memo, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { CommonServices } from "../../utils/commonServices";
import { useState } from "react";
import { orderSelector, updateEstimation } from "../../features/account/orderSlice";
import Skeleton from "react-loading-skeleton";

const EstimationAcceptanceStructure = memo((props) => {
    const commonServices = new CommonServices()

    const [bookingDate, setBookingDate] = useState(null);
    const { orderDetailsResp } = useSelector(orderSelector);
    const [extraServicesList, setExtraServicesList] = useState(null)

    useEffect(() => {
        if (orderDetailsResp) {
            const formattedDate = commonServices.formateDate(
                orderDetailsResp.created_at
            );
            setBookingDate(formattedDate);
            if (orderDetailsResp?.updatedExtraServiceList?.length > 0) {
                setExtraServicesList(orderDetailsResp?.updatedExtraServiceList)
            }
        }
    }, [orderDetailsResp]);



    return (<>
        <div class="order-estimation">
            <div class="row">
                <div className="col-lg-11 col-md-11 col-sm-11 pb-3">
                    <h5 class="text-center m-0">Review Estimate</h5>
                </div>
                {<div className="col-lg-1 col-md-1 col-sm-1">
                    <img
                        class="pointer"
                        src="../assets/icons/cross.png"
                        style={{ width: "20px" }}
                        onClick={() => {
                            props.handleClose();
                        }}
                        alt="..."
                    ></img>
                </div>}
                <hr class="m-0" />
                <div class="px-4 order-estimation-body">
                    <div class="estimate-order-details">
                        <div class="row order-details-body">
                            <div className="col-lg-2 col-md-2 col-sm-2 mx-3 my-2 p-0">
                                <img
                                    src={orderDetailsResp?.service_center_profile_pic}
                                    class="w-100 h-100"
                                    alt=""
                                />
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-6 align-items-center p-0 m-auto mx-0">
                                <p class="m-0 fw-600 service-center-name">
                                    {orderDetailsResp ? (
                                        orderDetailsResp.service_center_dispaly_name
                                    ) : (
                                        <Skeleton height={15} />
                                    )}
                                </p>
                                <p class="m-0 service-center-address text-truncate">
                                    {orderDetailsResp ? (
                                        orderDetailsResp.service_center_full_address
                                    ) : (
                                        <Skeleton height={15} />
                                    )}
                                </p>
                                {orderDetailsResp ? (
                                    <p class="m-0 booking-details">Booked on {bookingDate}</p>
                                ) : (
                                    <Skeleton height={15} />
                                )}
                            </div>
                            <hr class="m-0" />
                        </div>
                    </div>
                    <div class="w-100">
                        <h5 class="py-3 m-0 fw-500" style={{ fontSize: "18px" }}>
                            Mechanic Recommended
                        </h5>
                        <table class="row m-0 estimation-list-table">
                            <thead class="col-12 estimation-list-table-header">
                                <tr class="row ">
                                    <th class="col-lg-2  col-md-2 col-sm-2  border-right p-2 fw-600 text-center">S.No</th>
                                    <th class="col-lg-5  col-md-5 col-sm-4  border-right p-2 fw-600 text-start" >Service Name</th>

                                    <th class="col-lg-2  col-md-2 col-sm-1  p-2 border-right fw-600 text-center text-center">Qty.</th>
                                    <th class="col-lg-3   col-md-3 col-sm-3 m-0  p-2 fw-600  text-end">Cost</th>
                                </tr>
                            </thead>
                            <tbody class="col-12">
                                <div class="jobcard-list">
                                    {
                                        extraServicesList?.length > 0 && extraServicesList.map((item, index) => {
                                            return (
                                                <>
                                                    <tr class="row ">
                                                        <td class="col-lg-2 col-md-2 col-sm-2 border-right">
                                                            {index + 1}
                                                        </td>
                                                        <td class="col-lg-5  col-md-5 col-sm-4 border-right text-start" style={{ wordWrap: "anywhere" }}>
                                                            {item.jobcardServiceName}
                                                        </td>

                                                        <td class="col-lg-2  col-md-2 col-sm-1 border-right text-center">
                                                            {item.quantity}
                                                        </td>
                                                        <td class="col-lg-3  col-md-3 col-sm-3  m-0 text-end fw-600">
                                                            {commonServices.numberFormat(item.service_cost)}
                                                        </td>
                                                    </tr>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                                <tr class="row border-top ">
                                    <td class="col-lg-9 col-md-9 total text-center border-right ">
                                        Total
                                    </td>
                                    <td class="col-lg-3 col-md-3 col-sm-3 text-end m-0 fw-600">
                                        {commonServices.numberFormat(orderDetailsResp?.updatedExtraAmoutSummery?.totalAmount)}
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                        <div class="row m-0 px-3 py-2">
                            <div className="col-lg-7 col-md-7 col-sm-7">
                                <p class="m-0 final-amount" >
                                    Net Payable Extra
                                </p>
                            </div>
                            <div className="col-lg-5 col-md-5 col-sm-5">
                                <p class="m-0 text-center final-amount fw-600">{commonServices.numberFormat(orderDetailsResp?.updatedExtraAmoutSummery?.totalAmount)}
                                </p>
                            </div>
                        </div>
                        <div class="tip px-3  my-3">
                            <p class="m-0 fw-600">  Tip: You can continue the service without these add on services, but our technician recommends it.</p>
                        </div>
                        <div class="confirm-buttons">
                            <div class="d-flex">
                                <button class="m-auto mt-2 mb-3 estimate-accept-button"
                                    onClick={() => { props.handleUpdateEstimation(orderDetailsResp?.orderId, true) }}>
                                    Yes, Add these services
                                </button>
                            </div>
                            <div class="d-flex">
                                <button class="m-auto mt-2 mb-3 estimate-reject-button"
                                    onClick={() => { props.handleUpdateEstimation(orderDetailsResp?.orderId, false) }}>
                                    No, Continue without recommends
                                </button>
                            </div>
                            <div class="d-flex">
                                <a class="m-auto my-2 fw-600 download-estimate d-flex align-items-center"
                                    style={{ color: "#379b63", textDecoration: "none", fontSize: "15px" }}
                                    href={orderDetailsResp?.estimationInvoice}
                                    download
                                >
                                    Download Estimation  <img src="/assets/icons/downloadIcon.png" class="ms-1" width="6%" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
})

const EstimationConfirmationModal = ({ message, handleConfirmation }) => {
    return (
        <>

            <div class="estimation-confirmation-modal">
                <div class="my-4">
                    <p class="m-0 text-center message-head ">
                        Are you sure,
                    </p>
                    <p class="m-0 text-center message ">
                        {message}
                    </p>
                </div>
                <div class="mb-2 d-flex justify-content-center">
                    <button
                        class="cancel-btn"
                        onClick={() => {
                            handleConfirmation(false);
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        class="confirm-btn"
                        onClick={() => handleConfirmation(true)}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </>
    )
}

const OrderEstimation = (props) => {

    const dispatch = useDispatch()
    const [openConfirmationDialogue, setOpenConifirmationDialogue] = useState(false);
    const [data, setData] = useState(null)
    const [confirmationMessage, setConfirmationMessage] = useState("")
    const handleUpdateEstimation = (orderId, action) => {

        setOpenConifirmationDialogue(true);
        setData({ orderId: orderId, isEstimationAccepted: action })
        if (action) {
            setConfirmationMessage("You want to accept this estimation?");
        } else {
            setConfirmationMessage("You don't want to accept this estimation?")
        }
    }

    const handleEstimationConfirmation = (action) => {
        if (action) {
            props.closeOrderEstimation()
            dispatch(updateEstimation({ orderId: data.orderId, comment: "", estimation: data.isEstimationAccepted }))
        } else {
            setOpenConifirmationDialogue(false);
        }
    }

    return (
        <>
            <>
                <Modal
                    show={true}
                    centered
                >
                    <Modal.Body>
                        {!openConfirmationDialogue ? <EstimationAcceptanceStructure
                            handleClose={
                                props.closeOrderEstimation
                            }

                            handleUpdateEstimation={handleUpdateEstimation}
                        /> : <>
                            <EstimationConfirmationModal message={confirmationMessage} handleConfirmation={handleEstimationConfirmation} />
                        </>}
                    </Modal.Body>
                </Modal>
            </>
        </>
    )
}

export default OrderEstimation;