import { decode as atob } from "base-64";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import useRazorpay from "react-razorpay";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../common/Loader/Loader";
import { S3_BUCKET_URL } from "../../config";
import { STATUS_CODES } from "../../config/constants";
import {
  checkoutSelector,
  orderInitialize,
  orderInitializePayment,
  reset as resetCheckoutSlice,
  updateEnableBookingButton,
  updatePaymentFailureResponse,
  updatePaymentSuccessResponse,
} from "../../features/Checkout/checkoutslice";
import {
  customerProfileSelector,
  getCustomerDetails,
} from "../../features/account/customerProfileSlice";
import {
  addVehicle,
  updateVehicle,
  vehicleSelector,
} from "../../features/account/vehicleSlice";
import { updateWallet } from "../../features/account/walletSlice";
import { authSelector } from "../../features/auth/authSlice";
import {
  cartSelector,
  getCartItems,
  removeCartItem,
  reset,
  setOpenCartPage,
  updateCartDetails,
  updateCartVehicleId,
} from "../../features/cart/cartSlice";
import {
  applyCoupon,
  applyWalletAmount,
  couponsAndWalletSelector,
  getCoupons,
  removeCoupon,
  resetCouponAndWalletSlice,
  updateIsApplyingCoupon
} from "../../features/couponsAndWallet/couponsAndWallet";
import { homeselector } from "../../features/home/homeSlice";
import { getServiceCenterAllServices } from "../../features/servicepackage/servicepackageSlice";
import { CommonServices } from "../../utils/commonServices";
import { LoginOrRegistrationModalComponent } from "../Login/Login";

function Cart(props) {
  const commonServices = new CommonServices();
  const Razorpay = useRazorpay();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { selectedvehicle } = useSelector(homeselector);
  const { customerDetails } = useSelector(customerProfileSelector);
  const {
    enableBookingButton,
    selectedSchedule,
    selectedAddress,
    orderInitializationResponse,
    orderDetails,
    initPaymentResp,
  } = useSelector(checkoutSelector);
  const { addVehicleResponse, vehicleRegistrationNumber, updateVehicleResp } =
    useSelector(vehicleSelector);
  const { isAuthenticated, loginResponse, registerResp } =
    useSelector(authSelector);
  const { cartDetails, removeCartItemResp, cartItemsCount } =
    useSelector(cartSelector);
  const {
    getCouponsResponse,
    applyCouponResponse,
    removeCouponResponse,
    walletAmount,
    applyWalletAmountResponse,
    isApplyingCoupon
  } = useSelector(couponsAndWalletSelector);

  const [show, setShow] = useState(false);
  const [cartItemsList, setCartItemsList] = useState(null);
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [isWalletAmountApplied, setIsWalletAmountApplied] = useState(false);
  const [openCouponsModal, setOpenCouponsModal] = useState(false);
  const [proceedWithOrder, setProceedWithOrder] = useState(false);
  const [paymentMode, setPaymentMode] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    dispatch(getCartItems());
    dispatch(reset());

  }, []);

  const handleGoToCartPage = () => {
    dispatch(setOpenCartPage(true));
    dispatch(getCoupons(cartDetails?.data?.cartId));
    navigate("/services/mycart");
  };

  const handleCheckOut = () => {
    if (location.pathname === "/services/checkout") {
      if (enableBookingButton) {
        setProceedWithOrder(true);
      } else {
        toast.warning(
          "Please Select your available slot and check your selected Address",
          { toastId: "slotselection", autoClose: 2000, hideProgressBar: true }
        );
      }
    } else {
      if (isAuthenticated) {
        commonServices.setLocalStrg(
          "MMX_SERVICE_CENTER",
          JSON.stringify(cartDetails?.data?.serviceCenterId)
        );
        navigate("/services/checkout", { replace: true });
      } else {
        setShow(true);
      }
    }
  };

  useEffect(() => {
    if (location.pathname === "/services/mycart" && isAuthenticated) {
      setShow(false);
      dispatch(getCustomerDetails());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (
      loginResponse?.status === "success" &&
      loginResponse?.statuscode === 200
    ) {
      addSelectedVehicle();
    }
  }, [loginResponse]);

  useEffect(() => {
    if (registerResp?.statuscode === STATUS_CODES[200]) {
      addSelectedVehicle();
    }
  }, [registerResp]);

  const addSelectedVehicle = () => {
    if (isAuthenticated) {
      const selectedVehicle = commonServices.getLocalStrgJSON("MMX_VEHICLE");
      if (selectedVehicle) {
        const body = {
          vehicleBrandId: JSON.parse(selectedVehicle?.selectedbrand).brandId,
          vehicleModelId: JSON.parse(selectedVehicle?.selectedmodel)
            .vehicleModelId,
          vehicleFueltypeId: JSON.parse(selectedVehicle?.selectedfueltype)
            .fueltypeId,
          vehicleTypeId: selectedVehicle?.vehicleType,
          manufactured_year:
            selectedVehicle?.selectedYear && selectedVehicle.selectedYear,
        };
        dispatch(addVehicle(body));
      }
    }
  };

  useEffect(() => {
    if (
      addVehicleResponse &&
      addVehicleResponse.statuscode === STATUS_CODES[200]
    ) {
      commonServices.setLocalStrg(
        "MMX_SELECTED_VEHICLE",
        JSON.stringify(addVehicleResponse?.data)
      );
      const cartId = commonServices.getLocalStrg("MMX_CURRENT_CARTID");
      if (cartId !== null && cartId !== undefined && cartId !== "") {
        dispatch(updateCartVehicleId());
      }
    }
  }, [addVehicleResponse]);

  useEffect(() => {
    if (cartDetails) {
      if (cartDetails.statuscode === STATUS_CODES[200]) {
        setCartItemsList(cartDetails?.data?.cartIteams);
        commonServices.setLocalStrg(
          "MMX_CURRENT_CARTID",
          cartDetails.data.cartId
        );
        setShowLoader(false);
        // setTimeout(() => {
        //   setShowLoader(false);
        // }, [1000])
      } else if (cartDetails.statuscode === STATUS_CODES[300]) {
        // cart is empty
        setCartItemsList(null);
        localStorage.removeItem("MMX_CART");
        localStorage.removeItem("MMX_CURRENT_CARTID");
      } else {
        // this.getServiceCenters(false, true, {});
        // this.commonService.presentToast(cartDetails.message, "warning");
      }
    }
  }, [cartDetails]);

  const removeItem = (cartItemId) => {
    const cartId = commonServices.getLocalStrg("MMX_CURRENT_CARTID");
    dispatch(
      removeCartItem({
        cartId,
        cartItemId,
      })
    );
    const serviceCenter = commonServices.getLocalStrg(
      "MMX_CURRENT_CART_SERVICECENTER"
    );
  };

  useEffect(() => {
    if (
      removeCartItemResp &&
      removeCartItemResp.statuscode === STATUS_CODES[200]
    ) {
      toast.info("Item removed successfully!", {
        toastId: "removeItem1",
        autoClose: 2000,
        hideProgressBar: true,
      });
      const serviceCenter = commonServices.getLocalStrgJSON("MMX_SERVICE_CENTER")
      dispatch(getServiceCenterAllServices(serviceCenter));
      dispatch(getCartItems());
      dispatch(reset());
    }
  }, [removeCartItemResp]);

  const applySelectedCoupon = (coupon) => {
    dispatch(updateIsApplyingCoupon(true));
    dispatch(
      applyCoupon({
        cartId: cartDetails.data.cartId,
        couponId: coupon.couponId,
      })
    );

    setOpenCouponsModal(false);
  };

  useEffect(() => {
    if (applyCouponResponse) {
      if (applyCouponResponse.statuscode === STATUS_CODES[200]) {
        toast.info("Coupon Applied", {
          toastId: "couponApplied",
          autoClose: 2000,
          hideProgressBar: true,
        });
        setIsCouponApplied(true);
        dispatch(getCartItems());
      } else if (applyCouponResponse.statuscode === STATUS_CODES[300]) {
        toast.warning("Invalid coupon", {
          toastId: "invalidCoupon",
          autoClose: 2000,
          hideProgressBar: true,
        });
      } else if (applyCouponResponse.statuscode === STATUS_CODES[301]) {
        toast.warning("Invalid cart", {
          toastId: "Invalid cart",
          autoClose: 2000,
          hideProgressBar: true,
        });
      } else if (applyCouponResponse.statuscode === STATUS_CODES[302]) {
        toast.warning("Cart Empty", {
          toastId: "Cart Empty",
          autoClose: 2000,
          hideProgressBar: true,
        });
      } else if (applyCouponResponse.statuscode === STATUS_CODES[303]) {
        toast.warning("Invalid vehicle", {
          toastId: "Invalid vehicle",
          autoClose: 2000,
          hideProgressBar: true,
        });
      } else if (applyCouponResponse.statuscode === STATUS_CODES[304]) {
        toast.warning(
          `Min. cart value should be : ${applyCouponResponse?.minimum_order_amount}`,
          {
            toastId: "Invalid vehicle",
            autoClose: 2000,
            hideProgressBar: true,
          }
        );
      } else {
        toast.warning(applyCouponResponse.message, {
          toastId: "Invalid vehicle",
          autoClose: 2000,
          hideProgressBar: true,
        });
      }
    }
    dispatch(resetCouponAndWalletSlice());
  }, [applyCouponResponse]);

  const CouponsModal = () => {
    return (
      <div>
        <Modal
          className="coupon-modal"
          show={true}
          onHide={() => {
            setOpenCouponsModal(false);
          }}
        // backdrop="static"
        // keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#428372" }}>
              Available Offers
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              height: "fit-content",
              backgroundColor: "whitesmoke",
            }}
          >
            <div class="coupons-list">
              {getCouponsResponse?.data?.length > 0 ? (
                getCouponsResponse?.data.map((item) => {
                  return (
                    <div class="coupon mb-2">
                      <strong>
                        <span class="m-0" style={{ color: "#428372" }}>
                          {item.coupon_title}
                        </span>
                      </strong>
                      <ul class="m-0">
                        <li style={{ listStyle: "disc" }}>
                          Valid on selective car services :
                        </li>
                      </ul>
                      <p style={{ fontSize: "12px" }}>{item.serviceCats}</p>
                      <hr></hr>
                      <div class=" d-flex align-items-center justify-content-between  py-1 px-3">
                        {/* <strong> */}{" "}
                        <span class="apply m-0 border-0 py-1 px-2 fw-500">
                          {item.coupon_code}
                        </span>
                        {/* </strong> */}
                        <button
                          class=" m-0 pointer border-0 bg-transparent"
                          style={{ fontSize: "16px", color: "#428372" }}
                          onClick={() => {
                            applySelectedCoupon(item);
                          }}
                        >
                          <strong> APPLY</strong>
                        </button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <h5>No Coupons available for you yet</h5>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  const handleApplyCouponAction = () => {
    dispatch(getCoupons(cartDetails.data.cartId));
    setOpenCouponsModal(true);
  };

  const removeAppliedcoupon = (cartId) => {
    dispatch(updateIsApplyingCoupon(true));
    dispatch(removeCoupon(cartId));
  };

  useEffect(() => {
    if (removeCouponResponse) {
      if (removeCouponResponse.statuscode === STATUS_CODES[200]) {
        toast.info("Coupon removed", {
          toastId: "couponRemoved",
          autoClose: 2000,
          hideProgressBar: true,
        });
        setIsCouponApplied(false);
        dispatch(getCartItems());
      }
    }
    dispatch(resetCouponAndWalletSlice());
  }, [removeCouponResponse]);

  const handleApplyWalletAmount = (event) => {
    const data = {
      cartId: cartDetails?.data?.cartId,
      walletamountincluded: event.target.checked,
      walletamount: event.target.checked
        ? walletAmount.data.redeemableamount
        : 0,
    };
    dispatch(applyWalletAmount(data));
    setIsWalletAmountApplied(event.target.checked);
    setShowLoader(true);
  };

  useEffect(() => {
    if (applyWalletAmountResponse) {
      if (applyWalletAmountResponse.statuscode === STATUS_CODES[200]) {
        dispatch(getCartItems());
      }
    }
  }, [applyWalletAmountResponse]);

  const handlePayAfterService = () => {
    initializeOrder(2);
    setPaymentMode(2);
  };

  const handlePayNow = () => {
    initializeOrder(1);
    setPaymentMode(1);
  };

  const initializeOrder = (payment_mode = 1) => {
    setShowLoader(true);
    const cartId = commonServices.getLocalStrg("MMX_CURRENT_CARTID");
    const body = {
      cartId: cartId,
      slotId: selectedSchedule.selectedSlot.slotId,
      customerAddressId: commonServices.getLocalStrg("MMX_ADDRESSID"),
      slot_date: selectedSchedule.selectedDay.slot_date,
      pickup_addrress: selectedAddress,
      service_instruction: "Instructions goes here ",
      arrival_type: selectedSchedule.selectedDeliveryMode,
      payment_mode,
    };
    dispatch(orderInitialize(body));
  };

  useEffect(() => {
    if (orderInitializationResponse?.statuscode === STATUS_CODES[200]) {
      updateUserVehicle();
      if (paymentMode === 1) {
        paymentOnline();
      } else {
        paymentAfterservice(orderInitializationResponse.message);
      }
    } else {
      setShowLoader(false);
      toast.error(orderInitializationResponse?.message, {
        toastId: "initiationError",
        autoClose: 1000,
        hideProgressBar: true,
      });
      dispatch(reset());
    }
  }, [orderInitializationResponse]);

  const updateUserVehicle = () => {
    const SELECTED_VEHICLE = commonServices.getLocalStrg(
      "MMX_SELECTED_VEHICLE"
    );
    const MMX_SELECTED_VEHICLE = SELECTED_VEHICLE
      ? JSON.parse(SELECTED_VEHICLE)
      : "";
    if (
      MMX_SELECTED_VEHICLE.registration_no == "" ||
      MMX_SELECTED_VEHICLE.registration_no == null
    ) {
      const registration_no = vehicleRegistrationNumber.toUpperCase();
      const data = {
        registration_no: registration_no,
        customerVehicleId: MMX_SELECTED_VEHICLE.customerVehicleId,
      };
      dispatch(updateVehicle(data));
    }
  };

  useEffect(() => {
    if (updateVehicleResp?.statuscode === STATUS_CODES[200]) {
      const SELECTED_VEHICLE = commonServices.getLocalStrg(
        "MMX_SELECTED_VEHICLE"
      );

      let MMX_SELECTED_VEHICLE = SELECTED_VEHICLE
        ? JSON.parse(SELECTED_VEHICLE)
        : "";
      localStorage.removeItem("MMX_SELECTED_VEHICLE");
      MMX_SELECTED_VEHICLE.registration_no =
        vehicleRegistrationNumber.toUpperCase();
      commonServices.setLocalStrg(
        "MMX_SELECTED_VEHICLE",
        JSON.stringify(MMX_SELECTED_VEHICLE)
      );
    }
  }, [updateVehicleResp]);

  const paymentAfterservice = (message) => {
    const body = {
      orderId: orderDetails.orderId,
      payment_mode: 2,
      payment_status: 1,

      is_extra_estimation_pay: false,
    };

    dispatch(orderInitializePayment(body));
  };

  const paymentOnline = () => {
    const options = {
      description: "Service Booking",
      image: S3_BUCKET_URL + "assets/metamechx-logo.svg",
      order_id:
        orderDetails?.rezorpay_orderDetails.id ||
        commonServices.generateUUIDV4(),
      handler: function (response) {
        const paymentSuccessResponse = response;
        dispatch(updatePaymentSuccessResponse(response));
        const body = {
          orderId: orderDetails.orderId,
          payment_mode: 1,
          paymentDetails: {
            referenceId: orderDetails.orderReference,
            orderNo: orderDetails.orderNo,
            notes: orderDetails?.orderNo,
            amount: orderDetails?.total_pay_amount,
            ...paymentSuccessResponse,
          },
          paid_amount: orderDetails?.total_pay_amount,
          payment_status: 1,
        };
        if (
          response.razorpay_payment_id &&
          commonServices.isValidData(response.razorpay_payment_id)
        ) {
          dispatch(orderInitializePayment(body));
        } else {
          toast.error("Payment faild", "danger", {
            toastId: "paymentfailed",
            autoClose: 2000,
            hideProgressBar: true,
          });
          cancelCallback(response);
        }
      },
      currency: "INR",
      key: atob(atob(orderDetails?.rezorpay_orderDetails?.razorpay_key_id)),
      amount: Number(orderDetails?.total_pay_amount),
      name: " MetaMechX",
      notes: { orderNo: orderDetails.orderNo },
      orderNo: orderDetails.orderNo,
      prefill: {
        email: customerDetails.email,
        contact: customerDetails.mobile,
        name: customerDetails.full_name,
      },
      theme: {
        color: "#28B67E",
      },
      modal: {
        ondismiss: function () {
          dispatch(resetCheckoutSlice())
          setShowLoader(false);
        },
      },
    };
    const RazorpayCheckout = new Razorpay(options);
    try {
      RazorpayCheckout.open(options);
    } catch (e) {
      commonServices.setLocalStrg("MMX_PAYMENT_ERROR", JSON.stringify(e));
    }
  };
  const cancelCallback = (error) => {
    setShowLoader(false);
    let paymentFaildResponse = error;
    paymentFaildResponse.description = paymentFaildResponse.description
      ? JSON.parse(paymentFaildResponse.description)
      : "";
    dispatch(updatePaymentFailureResponse(paymentFaildResponse));
    const body = {
      orderId: orderDetails.orderId,
      payment_mode: 1,
      paymentDetails: {
        referenceId: orderDetails.orderReference,
        orderNo: orderDetails.orderNo,
        notes: orderDetails?.orderNo,
        amount: orderDetails?.total_pay_amount,
        ...paymentFaildResponse,
      },
      payment_status: 2,
    };
    dispatch(orderInitializePayment(body));
    if (
      error?.code !== 0 &&
      error?.description?.error?.code !== "BAD_REQUEST_ERROR" &&
      error?.description?.error?.reason !== "payment_cancelled"
    ) {
      toast.error("Payment faild! Please try again.", {
        autoClose: 1000,
        hideProgressBar: true,
        toastId: "paymentfailed",
      });

      navigate("/services/mycart");
    } else {
      toast.error("Payment faild! Please try again.", {
        autoClose: 1000,
        hideProgressBar: true,
        toastId: "paymentfailed",
      });
    }
  };

  useEffect(() => {
    if (initPaymentResp) {
      setShowLoader(false);
      const walletbody = {
        orderId: orderDetails?.orderId,
        ordertype: "ORDER",
        walletamount: orderDetails?.wallet_amount,
      };
      if (isWalletAmountApplied) {
        dispatch(updateWallet(walletbody));
      }
      dispatch(reset());
      dispatch(updateCartDetails(null))
      dispatch(resetCheckoutSlice());
      dispatch(updateEnableBookingButton(false));
      navigate("/order-successfull");
    }
  }, [initPaymentResp]);

  return (
    <>
      <div
        className={`col-lg-12 ${location.pathname === "/services/mycart" ||
          location.pathname === "/services/checkout"
          ? "d-block mt-5"
          : "cart_strip"
          }`}
      >
        <div className="card">
          <div className="cart-title text-center">
            {" "}
            Selected Services{" "}
            <strong>
              {cartDetails?.data?.service_center_name
                ? `from ${cartDetails?.data?.service_center_name}`
                : ""}{" "}
            </strong>
          </div>
          <div className="card-body">
            <div className="cart-img py-2">
              <div className="justify-content-center d-flex">
                <img
                  alt="..."
                  src="../assets/imgs/cart/basic_service-01.png"
                  className="img-fluid"
                />{" "}
              </div>
              <div className="justify-content-center d-flex">
                {selectedvehicle != null && selectedvehicle}
              </div>
            </div>
            {location.pathname === "/services" ||
              location.pathname === "/services/service-centers" ? (
              <>
                <div className="cartItems">
                  {cartItemsList && cartItemsList.length > 0 ? (
                    cartItemsList.map((item) => {
                      return (
                        <>
                          <div className="basic_service">
                            <div className="row align-items-center">
                              <div className="col-lg-2">
                                <img
                                  alt="..."
                                  src={item.serviceCatDesktopImage}
                                  className="service-cat-img img-fluid mt-1"
                                />
                              </div>
                              <div className="col-lg-5 p-0">
                                <a href="#">{item.service_name}</a>
                              </div>
                              <div className="price col-lg-4 d-flex align-items-center justify-content-end p-0">
                                {item.actual_service_cost !==
                                  item.after_discount_service_cost && (
                                    <p class="m-0 mx-1 actual-price">
                                      {/* <del> */}
                                      <i class="fa fa-inr" aria-hidden="true"></i>
                                      {item.actual_service_cost}
                                      {/* </del> */}
                                    </p>
                                  )}
                                <p class="m-0 mx-1 dicount-price">
                                  <i class="fa fa-inr" aria-hidden="true"></i>
                                  {item.after_discount_service_cost}
                                </p>
                              </div>
                              <div
                                className="col-lg-1"
                                onClick={() => {
                                  removeItem(item.cartItemId);
                                }}
                              >
                                <i
                                  class="fas fa-times pointer"
                                  style={{ color: "red" }}
                                ></i>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <div>
                        <img
                          alt="..."
                          src="../assets/imgs/cart/undraw_empty_cart_co35.png"
                          class="cart-img"
                        />
                      </div>
                      <p class="text-center" style={{ color: "#428372" }}>
                        Your Cart Is Empty now Lets service your gaadi!
                      </p>
                    </>
                  )}
                </div>
                {cartItemsList && cartItemsList.length > 0 && (
                  <>
                    <div className="basic_service border-bottom-0 p-2">
                      <div className="row align-items-center">
                        <div
                          className="col-lg-6 text-end"

                        >
                          <a href="#">ITEM TOTAL</a>
                        </div>
                        <div className="col-lg-6 d-flex align-items-end">
                          {cartDetails?.data?.totalServiceDiscount > 0 && <p class="m-0 mx-1">
                            <del>
                              <i class="fa fa-inr" aria-hidden="true"></i>
                              {cartDetails?.data?.totalCartAmount}
                            </del>
                          </p>}{" "}
                          &nbsp;
                          <p class="m-0 mx-1">
                            <i class="fa fa-inr" aria-hidden="true"></i>
                            {cartDetails?.data?.totalCartAmount -
                              cartDetails?.data?.totalServiceDiscount}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center">
                      <button
                        className="go-to-cart w-100 p-2 common-btn border-0 fw-bolder"
                        onClick={() => {
                          handleGoToCartPage();
                        }}
                      >
                        <i class="fas fa-shopping-cart"></i> Go To Cart
                      </button>
                    </div>
                  </>
                )}
              </>
            ) : (
              (location.pathname === "/services/mycart" ||
                location.pathname === "/services/checkout") && (
                <>
                  {cartDetails?.data?.totalCouponDiscount <= 0 && (
                    <div
                      className={`apply-coupons d-flex justify-content-start align-items-center mt-3 p-2 rounded-3 pointer mb-2 ${location.pathname === "/services/checkout" && "d-none"
                        }`}
                      onClick={() => {
                        handleApplyCouponAction();
                      }}
                    >
                      <img
                        src="../assets/icons/coupon.svg"
                        alt=""
                        style={{ width: "6%" }}
                      />{" "}
                      &nbsp; &nbsp;
                      <p class="m-0">Apply Coupon</p>
                    </div>
                  )}

                  <div
                    class={`${location.pathname === "/services/checkout" && "d-none"
                      }`}
                  >
                    {
                      cartDetails?.data?.couponDetails.coupon_code != null && (
                        <div class="apply-coupons d-flex justify-content-between align-items-center  px-4 py-2">
                          <div>
                            <img
                              src="../assets/icons/coupon.svg"
                              alt=""
                              style={{ width: "18%" }}
                            />{" "}
                            <strong>
                              {" "}
                              {cartDetails?.data?.couponDetails?.coupon_code}
                            </strong>{" "}
                            <br />
                            <p class="m-0">Coupon Applied</p>
                          </div>
                          <i
                            class="fas fa-trash-alt pointer"
                            style={{ color: "red" }}
                            onClick={() => {
                              removeAppliedcoupon(cartDetails?.data?.cartId);
                            }}
                          ></i>
                          {/* <span style={{ color: "red" }}>Remove</span> */}
                        </div>
                      )}

                    {!isAuthenticated ? (
                      <p class="text-end">
                        <strong
                          style={{ color: "#62a781" }}
                          class="pointer"
                          onClick={() => {
                            setShow(true);
                          }}
                        >
                          Login
                        </strong>{" "}
                        to apply Wallet amount
                      </p>
                    ) : (
                      walletAmount?.data?.redeemableamount > 0 && (
                        <div className="apply-coupons d-flex align-items-center mt-3 p-2 rounded-3 mb-2">
                          <input
                            type="checkbox"
                            class="pointer"
                            onChange={(event) => {
                              handleApplyWalletAmount(event);
                            }}
                            checked={
                              cartDetails?.data?.is_walletamount_included
                                ? true
                                : false
                            }
                          />
                          &nbsp; &nbsp;
                          <label>
                            <p class="m-0">
                              Use your {walletAmount?.data?.redeemableamount}/-
                              of Metamechx wallet
                            </p>
                          </label>
                        </div>
                      )
                    )}
                  </div>
                  <div class="price-details  p-2 rounded-3 ">
                    <h6>
                      Bill Details ({cartDetails?.data?.cartIteams.length})
                    </h6>
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="m-0">Item Total</p>
                      <p class="m-0">
                        {commonServices.numberFormat(
                          cartDetails?.data?.totalCartAmount
                        )}
                      </p>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="m-0">Discount Amount</p>
                      <p class="m-0">
                        -
                        {commonServices.numberFormat(
                          cartDetails?.data?.totalServiceDiscount
                        )}
                      </p>
                    </div>
                    {
                      cartDetails?.data?.totalCouponDiscount !== 0 && (
                        <>
                          <div class="d-flex justify-content-between align-items-center">
                            <p class="m-0">Coupon Amount</p>
                            <p class="m-0">
                              -
                              {commonServices.numberFormat(
                                cartDetails?.data?.totalCouponDiscount
                              )}
                            </p>
                          </div>
                        </>
                      )}
                    {cartDetails?.data?.totalWalletDiscount > 0 && (
                      <div class="d-flex justify-content-between align-items-center">
                        <p class="m-0">Wallet Amount</p>
                        <p class="m-0">
                          -
                          {commonServices.numberFormat(
                            cartDetails?.data?.totalWalletDiscount
                          )}
                        </p>
                      </div>
                    )}
                    <div class="d-flex justify-content-between align-items-center">
                      <p>
                        <strong>You PAY</strong>
                      </p>
                      <p>
                        <strong>
                          {commonServices.numberFormat(
                            cartDetails?.data?.finalPayAmount
                          )}
                        </strong>
                      </p>
                    </div>
                    {proceedWithOrder &&
                      enableBookingButton &&
                      location.pathname === "/services/checkout" ? (
                      <div class="payment payment-btns d-flex justify-content-center">
                        <button
                          class="p-1 pay-now-btn mx-2 rounded-3 border-0"
                          onClick={handlePayNow}
                          id="Pay Now"
                        >
                          Pay Now
                        </button>
                        <button
                          class="p-1 mx-2  rounded-3 border-0"
                          onClick={handlePayAfterService}
                          id="Pay After Service"
                        >
                          Pay After Service
                        </button>
                      </div>
                    ) : (
                      <>
                        <div
                          className={`w-100 p-2 rounded-3 border-0 text-center pointer ${!enableBookingButton &&
                            location.pathname === "/services/checkout"
                            ? "disabled"
                            : "enabled"
                            }`}
                          onClick={() => {
                            handleCheckOut();
                          }}
                        >
                          <strong class="text-light">
                            {" "}
                            {location.pathname === "/services/checkout"
                              ? "Book Now"
                              : "Check Out"}
                          </strong>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )
            )}
          </div>
        </div>
      </div>
      {show && (
        <LoginOrRegistrationModalComponent
          show={show}
          setShow={() => {
            setShow(false);
          }}
        />
      )}
      {openCouponsModal && CouponsModal()}
      {(showLoader || isApplyingCoupon) && <Loader />}
    </>
  );
}

export default Cart;
