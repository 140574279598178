import { useMediaQuery } from "react-responsive";
import { CommonServices } from "../../utils/commonServices";

const CartRemainderNotifications = (props) => {

    const { notification } = props;
    const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
    const commonServices = new CommonServices();
    return (
        <>
            <div class="each-payment-notification">
                <div class="success " style={{ width: "fit-content", backgroundColor: "red", }}>
                    <p className="m-0" style={{ width: "fit-content" }}>SUCCESSFULL</p>
                </div>
                <div class="row" style={{ margin: "0px 8px", padding: "16px 0px" }}>
                    <div className="col-lg-2 col-md-2 col-sm-2 d-flex">
                        <img
                            src="/assets/icons/notification-icons/ready-for-delivery.png"
                            className="w-100 m-auto"
                            alt="no-img"
                        />
                    </div>
                    <div className="col-lg-10 col-md-10 col-sm-10 ps-0">
                        <div className="row m-0">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <p class="title m-0">{notification.title}</p>
                                <p class="date my-1 text-start">
                                    {commonServices.formateDate(notification.created_at)}
                                </p>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CartRemainderNotifications