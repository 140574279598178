import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import SkeletonLoader from "../../common/SkeletonLoaders/SkeletonLoaders";
import {
  cancelOrder,
  getOrderDetails,
  getOrdersList,
  orderSelector,
  updateOrderDetails,
  setSelectedOrder,
  reset
} from "../../features/account/orderSlice";
import OrderDetailsModal from "../OrderDetails/OrderDetails";
import { useMediaQuery } from "react-responsive";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router";
import CancelOrderConfirmModal from "./CancelOrderConfirmModal";
import { toast } from "react-toastify";
import Loader from "../../common/Loader/Loader";
import OrderEstimation from "../OrderEstimation/OrderEstimation";
import OrderDetails from "../OrderDetails/OrderDetails";
import { STATUS_CODES } from "../../config/constants";
import { CommonServices } from "../../utils/commonServices";
const Orders = () => {
  const commonServices = new CommonServices()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, ordersList, getOrdersListResp, orderCancelResp, orderDetailsResp, updateEstimationResp, selectedOrder, isNavigatedFromNotifications } =
    useSelector(orderSelector);
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const [orderHistoryList, setOrderHistoryList] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(0);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [showEstimationDetails, setShowEstimationDetails] = useState(false);
  const [showDetails, setShowDetails] = useState(false)
  const [showCancelOrderConfirmationModal, setShowCancelOrderConfirmationModal] = useState(false)
  // const [selectedOrder, setSelectedOrder] = useState(null)
  const orderStatus = [
    {
      status: 1,
      color: "#ffc107",
    },
    {
      status: 2,
      color: "#70edfa",
    },
    {
      status: 3,
      color: "#eb70fa",
    },
    {
      status: 4,
      color: "#32a9fe",
    },
    {
      status: 5,
      color: "#09fe05",
    },
    {
      status: 6,
      color: "#09fe05",
    },
    {
      status: 7,
      color: "#ff0000",
    },
  ];
  const [getListBody, setGetListBody] = useState({
    limit: 10,
    start: 0,
    filter: {
      order_status: null,
      orderNo: ""
    }
  });

  useEffect(() => {
    dispatch(reset())
    dispatch(getOrdersList(getListBody));
    if (isNavigatedFromNotifications) {
      handleShowOrderDetails(selectedOrder)
    }
  }, []);

  useEffect(() => {
    if (ordersList?.length > 0) {
      setOrderHistoryList([...orderHistoryList, ...ordersList]);
      setGetListBody({ ...getListBody, start: getListBody.start + 10 });
    }
  }, [ordersList]);

  const filterOrders = (status) => {
    const body = {
      order_status: status,
      orderNo: ""
    }
    setGetListBody({ ...getListBody, limit: 10, start: 0, filter: body })
    setCurrentStatus(status);
    setOrderHistoryList([])
    dispatch(getOrdersList({ ...getListBody, limit: 10, start: 0, filter: body }));

  };

  const handleShowOrderDetails = (order) => {
    setShowDetails(true)
    dispatch(updateOrderDetails(null));
    dispatch(getOrderDetails(order.orderId));
    commonServices.setLocalStrg("MMX_SELECTED_ORDER_ID", order.orderId)
  };

  useEffect(() => {
    if (orderDetailsResp && showDetails) {
      if (isMobile) {
        navigate("/order-details")
      }

      else if (!isMobile && orderDetailsResp?.estimation_status === 1) {
        setShowEstimationDetails(true);
      } else {
        setShowOrderDetails(true);
      }
    }
  }, [orderDetailsResp])

  const loadData = () => {
    setTimeout(() => {
      dispatch(getOrdersList(getListBody));
    }, 2000);
  };

  const handleCancelOrderYes = (selectedOrder) => {
    dispatch(cancelOrder(selectedOrder.orderId))
  }

  useEffect(() => {
    if (orderCancelResp?.statuscode === 200) {
      setOrderHistoryList([])
      dispatch(reset())
      dispatch(getOrdersList({ ...getListBody, limit: 10, start: 0 }));
      toast.info(orderCancelResp.message, { toastId: "cancel order", autoClose: 2000, hideProgressBar: true, })
    }
  }, [orderCancelResp]);

  useEffect(() => {
    if (updateEstimationResp?.statuscode === STATUS_CODES[200]) {
      setShowEstimationDetails(false);
      dispatch(getOrderDetails(orderDetailsResp?.orderId))
    }
  }, [updateEstimationResp])

  useEffect(() => {
    return () => { setShowDetails(false) }
  }, [])
  return (
    <>
      <div class="orders">
        <h5>Order History</h5>
        <div class="my-4">
          <button
            class={`status-btn  ${currentStatus === 0 ? "active-status-btn" : "inactive-status-btn"
              } border-0 rounded-3 px-3 py-1 fw-500 me-3`}
            onClick={() => {
              filterOrders(0);
            }}
          >
            All
          </button>
          <button
            class={`status-btn ${currentStatus === 1 ? "active-status-btn" : "inactive-status-btn"
              }  border-0 rounded-3 px-3 py-1 fw-500 me-3`}
            onClick={() => {
              filterOrders(1);
            }}
          >
            In Progress
          </button>
          <button
            class={`status-btn ${currentStatus === 6 ? "active-status-btn" : "inactive-status-btn"
              } border-0 rounded-3 px-3 py-1 fw-500 me-3`}
            onClick={() => {
              filterOrders(6);
            }}
          >
            Delivered
          </button>
          <button
            class={`status-btn ${currentStatus === 7 ? "active-status-btn" : "inactive-status-btn"
              } border-0 rounded-3 px-3 py-1 fw-500 me-3`}
            onClick={() => {
              filterOrders(7);
            }}
          >
            Cancelled
          </button>
        </div>
        <InfiniteScroll
          style={{ overflowX: "hidden" }}
          dataLength={orderHistoryList?.length || 10} //This is important field to render the next data
          next={(event) => loadData()}
          hasMore={
            getOrdersListResp?.message === "There is no orders you have."
              ? false
              : true
          }
          loader={<SkeletonLoader isRectangle={true} count={1} />}
          height={400}
        >
          <div class="orders-list">
            {orderHistoryList?.length > 0 &&
              orderHistoryList.map((order) => {
                return (
                  <div
                    class="each-order mb-2"
                  >
                    <div class="py-3 px-4 d-flex align-items-center justify-content-between">
                      <span
                        class="fw-600 headers"
                        style={{
                          color: `${orderStatus.find(
                            (item) => item.status == order.orderStatus.status
                          ).color
                            }`,
                        }}
                      >
                        Order ID: {order.orderNo}
                      </span>
                      { }
                      <span
                        class="fw-600 headers"
                        style={{
                          color: `${orderStatus.find(
                            (item) => item.status == order.orderStatus.status
                          ).color
                            }`,
                        }}
                      >
                        {order.orderStatus.name}
                      </span>
                    </div>
                    <hr class="m-0" />
                    <div className="p-3 w-100">
                      <div class="row">
                        <div className="col-lg-2 col-md-3 col-sm-4">
                          <img
                            // src="../assets/imgs/service_package/package1-01.png"
                            src={order.service_center_profile_pic}
                            class="w-100"
                          ></img>
                        </div>
                        <div class="col-lg-10 col-md-9 col-sm-8  my-auto">
                          <div class="row mb-2">
                            <p class="service-center-name col-lg-8 col-md-8 col-sm-8 m-0">
                              {order.service_center_name}
                            </p>
                            <p class="service-center-name col-lg-4 col-md-4 col-sm-4 p-0 m-0 text-end">
                              <i class="fa fa-inr" aria-hidden="true"></i>
                              {order.total_pay_amount}.00/-
                            </p>
                          </div>
                          <p class="service-name fw-600">{order.serviceCat}</p>
                        </div>
                      </div>
                    </div>
                    <hr class="m-0" />
                    <div class={`py-3 px-4 d-flex align-items-center ${order.orderStatus.status === 1 ? "justify-content-between " : "justify-content-end "}`}>
                      {order.orderStatus.status === 1 && <span class="fw-600 headers cancel-service pointer" onClick={() => { setShowCancelOrderConfirmationModal(true); dispatch(setSelectedOrder(order)) }}>Cancel Service</span>}
                      <span class="fw-600 headers track-order pointer" onClick={() => {
                        handleShowOrderDetails(order);
                      }}>Track your order</span>
                    </div>
                  </div>
                );
              })}
            {showCancelOrderConfirmationModal && <CancelOrderConfirmModal handleClose={() => { setShowCancelOrderConfirmationModal(false) }} handleConfirmation={() => { handleCancelOrderYes(selectedOrder) }} />}
            {isLoading && <SkeletonLoader isRectangle={true} count={3} />}
          </div>
        </InfiniteScroll>
      </div>
      {showOrderDetails && (
        <Modal
          className="order-details-modal"
          show={showOrderDetails}
          onHide={() => setShowOrderDetails(false)}
          centered
        >
          <Modal.Body>
            <OrderDetails handleClose={() => {
              setShowOrderDetails(false);
            }} />
          </Modal.Body>
        </Modal>
      )}
      {showEstimationDetails && <OrderEstimation closeOrderEstimation={() => setShowEstimationDetails(false)} />}
      {isLoading && <Loader />}
    </>
  );
};

export default Orders;
